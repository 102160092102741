import { Result } from "antd";
import React from "react";

export function Page404Screen() {
  return (
    <main
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result status="404" />
    </main>
  );
}
