import { Divider, Segmented, Tabs } from "antd";
import React from "react";
import { OrganizationShowView } from "./organization/show";
import { OrganizationDepartmentView } from "./organization/department";
import { OrganizationGradeView } from "./organization/grades";
import { OrgAttendances } from "./organization/attendances";

export function OrganizationScreen() {
  const [section, setSection] = React.useState<any>("presentation");
  return (
    <>
      <main>
        <Segmented
          options={[
            {
              icon: null,
              value: "presentation",
              label: "Notre entreprise",
              title: "Présentation de notre entreprise",
            },
            {
              icon: null,
              value: "department",
              label: "Départements",
              title: "Nos départements",
            },
            {
              icon: null,
              value: "service",
              label: "Services",
              title: "Nos services",
            },
            {
              icon: null,
              value: "grade",
              label: "Fonctions",
              title: "Gestion des fonctions",
            },
            {
              icon: null,
              value: "presence",
              label: "Présences",
              title: "Gestion des présences",
            },
            /*{
              icon: null,
              value: "service",
              label: "Services",
              title: "Nos services",
            },*/
          ]}
          onChange={(v) => setSection(v)}
          value={section}
          block
          style={{ background: "#DDD" }}
        />
        <Divider />
        {section === "presentation" && <OrganizationShowView />}
        {section === "department" && <OrganizationDepartmentView />}
        {section === "grade" && <OrganizationGradeView />}
        {section === "presence" && <OrgAttendances />}
      </main>
    </>
  );
}
