import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useParams } from "react-router-dom";
import payslipService from "../../services/payslipService";
import { setPayslip } from "../../redux/payslipsReducer";
import { Card, Result, Typography, notification } from "antd";
import { isEmpty } from "lodash";
import { PayslipCreation } from "./file";
import { LoaderComponent } from "../../components/loader";

export function CardViewScreen() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const dispatch = useDispatch();
  const agent = useSelector((state: RootState) => state.personal.current);
  const payslip = useSelector((state: RootState) => state.payslip.current);

  const onInit = React.useCallback(async () => {
    const uid = params.id as string;
    setLoading(true);
    await payslipService
      .get(uid)
      .then((response) => {
        dispatch(setPayslip(response.data));
      })
      .catch((reason) => {
        const error = payslipService.getError(reason);
      });
    setLoading(false);
  }, [params]);

  React.useEffect(() => {
    onInit();
  }, [params]);

  return (
    <>
      <main>
        {!loading && !isEmpty(payslip) && (
          <Card style={{ maxWidth: 800, margin: "auto" }}>
            <PayslipCreation />
          </Card>
        )}
        {!loading && isEmpty(payslip) && (
          <Card>
            <Result title="Référence non trouvée" status="404" />
          </Card>
        )}
      </main>
      <LoaderComponent loading={loading} />
    </>
  );
}
