import { isEmpty, random } from "lodash";
import dateHelpers from "./dateHelpers";

const appName = "yeccolapp";
const refKey = "yecc-ref";

const setAppTitle = (value: string) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}
export function getLessonPoster(files: any[]) {
  const index = random(false);
  const images = files.filter((p) => p.typeMime.search("image") != -1);
  let response = `https://picsum.photos/800/600?random=${index}`;
  if (images.length > 0) {
    response = images[0].file;
  }
  return response;
}
const yecKey = "yeccolapp_admin";
const tokenKey = "yeccolapp_admin_token";

export function getToken(): string | null {
  let response = null;
  console.log("GET");
  const item = localStorage.getItem(tokenKey);
  if (typeof item === "string") {
    response = item;
  }
  return response;
}

export function getGroupTitle(group: any) {
  if (isEmpty(group)) {
    return "";
  }
  const type = group?.type;
  switch (type) {
    case "cycle":
      return "cycle";
    case "section":
      return "section";
    case "option_class":
      return "option";
    case "faculty":
      return "faculté";
    case "department":
      return "département";
    case "option":
      return "option";
    default:
      return "";
  }
}

const payslipCategories = [
  { value: "indemnite", title: "Indemnités additionnelles" },
  { value: "retenu", title: "Retenues sur salaire" },
  { value: "patronal", title: "Charges patronales" },
  { value: "prime", title: "Primes" },
  { value: "other", title: "Autres" },
];

export function getTitleValue(value: string) {
  const result = payslipCategories.find((p) => p.value === value)?.title ?? "";
  return result;
}

export function getFileURL(value?: string) {
  let url: string | undefined;
  if (!isEmpty(value)) {
    url = `${process.env.REACT_APP_UPLOAD_URL}${value}`;
  }
  return url;
}

export const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export function notify(message: string) {
  if (!("Notification" in window)) {
    alert("Ce navigateur ne prend pas en charge la notification de bureau");
  } else if (Notification.permission === "granted") {
    // Si tout va bien, créons une notification
    const notification = new Notification(message);
  }

  // Sinon, nous devons demander la permission à l'utilisateur
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      // Si l'utilisateur accepte, créons une notification
      if (permission === "granted") {
        const notification = new Notification(message);
      }
    });
  }
}

export const roles = [
  "ROLE_USER",
  "ROLE_DEV",
  "ROLE_ADMIN",
  "ROLE_RH",
  "ROLE_R_ADMIN",
];

export const typesOfVacations = [
  "Congé de maternité",
  "Congé de paternité",
  "Congé annuel",
  "Autres",
];

export function haveRightRoles(rightRoles: string[], roles: any[]) {
  const result = roles?.some(
    (p) => rightRoles?.findIndex((x) => x === p) !== -1,
  );
  return result;
}

export function getLeaveStatus(value: any) {
  let result = "";
  let badge = "in_pending";
  const status = value.status;
  const startAt = value.startAt;
  const finishAt = value.finishAt;
  const date = new Date();
  const diffStartDate = dateHelpers.getDifference(startAt, date, "days");
  const diffFinishDate = dateHelpers.getDifference(finishAt, date, "days");
  if (status === "in_pending") {
    if (diffStartDate <= 0) {
      result = "Autorisation incertaine";
      badge = "rejected";
    } else {
      result = "Autorisation en attente";
      badge = "in_pending";
    }
  } else if (result === "in_progress") {
    if (diffStartDate >= 0 && diffFinishDate <= 0) {
      result = "En plein congé";
      badge = "in_progress";
    } else if (diffStartDate < 0) {
      result = "Congé validé";
      badge = "in_progress";
    } else {
      result = "Congé dépassé";
      badge = "in_progress";
    }
  } else if (result === "canceled") {
    result = "Annulé";
    badge = "canceled";
  } else if (result === "rejected") {
    result = "Rejeté";
    badge = "canceled";
  } else {
    result = "";
    badge = "none";
  }

  return {
    result,
    badge,
  };
}

export {
  appName,
  getUid,
  refKey,
  setAppTitle,
  yecKey,
  tokenKey,
  payslipCategories,
};
