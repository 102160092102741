import { Button, Table } from "antd";
import React from "react";
import { CreateDepartment } from "./createDepartment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Icon } from "@iconify/react";
import { CreateGrade } from "./createGrade";
import { haveRightRoles } from "../../utils/helper/title";

export function OrganizationGradeView() {
  const grades = useSelector((state: RootState) => state.grade.all);
  const roles = useSelector((state: RootState) => state.user.roles);
  const [current, setCurrent] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);

  function handleOpen(value: any) {
    setCurrent(value);
    setOpen(true);
  }
  return (
    <section>
      <CreateGrade />
      <Table
        columns={[
          { dataIndex: "name", title: "Fonction" },
          { dataIndex: "salary", title: "Salaire de base" },
          {
            dataIndex: "agents",
            title: "Agents",
            render(value, record, index) {
              return value?.length ?? 0;
            },
          },
          {
            render(value, record, index) {
              return (
                <div>
                  <Button onClick={() => handleOpen(value)} shape="circle">
                    <Icon icon="el:edit" />
                  </Button>
                </div>
              );
            },
            hidden: !haveRightRoles(["ROLE_ADMIN", "ROLE_DEV"], roles),
          },
          // { dataIndex: "action", title: "Actions" },
        ]}
        dataSource={grades}
        className="table__1"
        pagination={false}
      />
      <CreateGrade edit item={current} setVisible={setOpen} visible={open} />
    </section>
  );
}
