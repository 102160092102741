import { Typography } from "antd";
import React from "react";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl: boolean;
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  children,
  rtl,
  ...rest
}) => {
  return (
    <div className="sidebar__header" {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="sidebar__logo"
          style={
            rtl
              ? { marginLeft: "10px", marginRight: "4px" }
              : { marginLeft: "4px", marginRight: "10px" }
          }
        >
          C
        </div>
        <Typography.Text
          style={{
            fontWeight: "bold",
            margin: 0,
            fontSize: 25,
            color: "#0098e5",
          }}
        >
          CAMI
        </Typography.Text>
      </div>
    </div>
  );
};
