import { isEmpty } from "lodash";
import { AgentScreen } from "./screens/agentScreen";
import { OrganizationScreen } from "./screens/orgScreen";
import { OrganigramScreen } from "./screens/organigramScreen";
import { AgentViewScreen } from "./screens/agent/view";
import { PayslipCreateScreen } from "./screens/paylips/view";
import { CardViewScreen } from "./screens/paylips/card";
import { LoginScreen } from "./screens/login";
import { CalendarScreen } from "./screens/calendar";
import { DashboardScreen } from "./screens/dashboard";
import { MyPayslipScreen } from "./screens/myPayslip";
import { Icon } from "@iconify/react";
import React from "react";

declare type routeInterface = {
  path: string;
  layout: "home" | "admin";
  component: any | null;
  icon?: string;
  parent?: string;
  isMenu?: boolean;
  label?: string;
  title?: string;
  extract?: string;
  description?: string;
  ogImage?: string;
  order?: number;
};

export function getRoutePathOfComponent(layout: string, path: string) {
  let result: string = `${layout}`;
  if (isEmpty(layout)) {
    result = "";
  } else if ((layout[0] ?? "" ?? "") !== "/") {
    result = "/" + result;
  }
  if ((path[0] ?? "" ?? "") !== "/") {
    result += "/" + path;
  } else {
    result += path;
  }
  return result;
}

const routes: routeInterface[] = [
  {
    component: LoginScreen,
    layout: "home",
    path: "/",
    title: "Connexion",
  },
  {
    component: null,
    layout: "home",
    path: "/config",
    title: "Configuration",
  },
  {
    component: DashboardScreen,
    layout: "admin",
    path: "/",
    title: "Tableau de bord",
    label: "Tableau de bord",
    isMenu: true,
    icon: "ic:baseline-dashboard",
  },
  {
    component: AgentScreen,
    layout: "admin",
    path: "/agents",
    title: "",
    label: "Employés",
    isMenu: true,
    icon: "medical-icon:i-care-staff-area",
  },
  {
    component: null,
    layout: "admin",
    path: "/agents/create",
    title: "Création d'employé",
    label: "",
    isMenu: false,
  },
  {
    component: OrganizationScreen,
    layout: "admin",
    path: "/organization",
    title: "",
    label: "Organisation",
    isMenu: true,
    icon: "gg:organisation",
  },
  {
    component: OrganizationScreen,
    layout: "admin",
    path: "/payslips/:id/view",
    title: "",
    label: "Organisation",
    isMenu: false,
  },
  {
    component: OrganigramScreen,
    layout: "admin",
    path: "/organigram",
    title: "Organigrame",
    label: "Organigrame",
    isMenu: false,
  },
  {
    component: AgentViewScreen,
    layout: "admin",
    path: "/agents/:id/view",
    title: "",
    label: "",
    isMenu: false,
  },
  {
    component: null,
    layout: "admin",
    path: "/agents/:id/card",
    title: "",
    label: "",
    isMenu: false,
  },
  {
    component: CardViewScreen,
    layout: "admin",
    path: "/cards/:id/view",
    title: "",
    label: "",
    isMenu: false,
  },
  {
    component: PayslipCreateScreen,
    layout: "admin",
    path: "/payslips/:id/",
    title: "",
    label: "",
    isMenu: false,
  },
  {
    component: CalendarScreen,
    layout: "admin",
    path: "/calendar",
    title: "Calendrier",
    label: "Calendrier",
    isMenu: true,
    icon: "ph:calendar-fill",
  },
  {
    component: CalendarScreen,
    layout: "admin",
    path: "/presences",
    title: "Présences",
    label: "Présences",
    isMenu: false,
  },
  {
    component: MyPayslipScreen,
    layout: "admin",
    path: "/mypayslips",
    title: "",
    label: "",
    isMenu: false,
  },
];

export { routes };
