import axios from "axios";
import config from "./config";

export default axios.create({
  //baseURL: process.env.REACT_APP_ENV=='int'?process.env.REACT_APP_URL_API:process.env.REACT_APP_URL_API_PROD,
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_URL,
});
