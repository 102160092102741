import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import sectionReducer from "./sectionReducer";
import departmentReducer from "./departmentReducer";
import personalReducer from "./personalReducer";
import payslipsReducer from "./payslipsReducer";
import leaveReducer from "./leaveReducer";
import gradeReducer from "./gradeReducer";
import attendanceReducer from "./attendanceReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    section: sectionReducer,
    department: departmentReducer,
    personal: personalReducer,
    payslip: payslipsReducer,
    leave: leaveReducer,
    grade: gradeReducer,
    attendance: attendanceReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
