import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useFormik } from "formik";
import userService from "../../services/userService";
import { isEmpty, toNumber } from "lodash";
import { setCurrentUser } from "../../redux/userReducer";
import { DillInput } from "../../components/input";
import userHelper from "../../utils/helper/userHelper";
import { AddSection } from "./addSection";
import personalService from "../../services/personalService";
import { setPersonal } from "../../redux/personalReducer";
import { LoaderComponent } from "../../components/loader";
import { PayslipCreation } from "./file";
import { getTitleValue, payslipCategories } from "../../utils/helper/title";
import dateHelpers from "../../utils/helper/dateHelpers";
import * as yup from "yup";
import type { DragEndEvent } from "@dnd-kit/core";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CloseOutlined, EditFilled } from "@ant-design/icons";
import payslipService from "../../services/payslipService";
import { setPayslip } from "../../redux/payslipsReducer";
import attendanceService from "../../services/attendanceService";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowSection = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: "move",
    ...(isDragging ? { position: "relative" } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

export function PayslipCreateScreen() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const dispatch = useDispatch();
  const [attendances, setAttendances] = React.useState<any[]>([]);
  const [totalWorkDay, setTotalWorkDay] = React.useState(0);
  const agent = useSelector((state: RootState) => state.personal.current);
  const payslip = useSelector((state: RootState) => state.payslip.current);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [sections, setSections] = React.useState<any[]>([]);
  const [currentSection, setCurrentSection] = React.useState<any>({});
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      date: "",
      date1: "",
      date2: "",
      salary: "0",
      cnss: "",
      patronal: "",
    },
    onSubmit,
    validationSchema: yup.object({
      date: yup.date().typeError("Une valeur de date").required("Champ requis"),
      date1: yup
        .string()
        .typeError("Une valeur de date")
        .required("Champ requis"),
      date2: yup
        .string()
        .typeError("Une valeur de date")
        .required("Champ requis"),
      salary: yup
        .number()
        .required("Champ requis")
        .typeError("Une valeur numérique est exigée")
        .min(0, "La valeur minimale ne doit pas être 0"),
      cnss: yup
        .number()
        .typeError("Une valeur numérique est exigée")
        .min(0, "La valeur minimale ne doit pas être 0")
        .max(100, "La valeur maximale est 100"),
      patronal: yup
        .number()
        .typeError("Une valeur numérique est exigée")
        .min(0, "La valeur minimale ne doit pas être 0")
        .max(100, "La valeur maximale est 100"),
    }),
  });

  function handleSubmit() {
    formik.handleSubmit();
  }

  async function getpayslip(personal: any) {
    let result: any = {};
    await payslipService
      .getByKey(`personal=${personal.uid}&year=${year}&month=${month}`)
      .then((response) => {
        const list = payslipService.getData(response);
        if (list.length > 0) {
          const item = list[0];
          initSections(item);
          dispatch(setPayslip(item));
        } else {
          initSections();
          dispatch(setPayslip({}));
        }
      })
      .catch((reason) => {
        console.log("Reason", reason);
      });
    return result;
  }

  const onChangeDate = React.useCallback(() => {
    const myDate = formik.values.date;
    const dt = dateHelpers.getDateOnly(myDate)?.toDate();
    try {
      /**@ts-ignore */
      setYear(dt.getFullYear());
      /**@ts-ignore */
      setMonth(dt.getMonth() + 1);
    } catch (error) {}
  }, [formik.values.date]);

  React.useEffect(() => {
    onChangeDate();
  }, [formik.values.date]);

  function initSections(card?: any) {
    if (isEmpty(card)) {
      setSections([
        {
          title: "Cotisations sociales salarié",
          value: `${agent.baseSalary}`,
          isDeductible: true,
          category: "retenu",
          rate: "10",
        },
      ]);
    } else {
      const elems: any[] = [];
      [...card.sections]
        .sort((a, b) => a.priority - b.priority)
        .forEach((p: any) => {
          elems.push({ ...p, value: p.amount, title: p.designation });
        });
      setSections(elems);
    }
  }

  function onSubmit() {
    const { cnss, date, date1, date2, patronal, salary } = formik.values;
    const d1 = dateHelpers.isSameMoment(date, date1, "month");
    const d2 = dateHelpers.isSameMoment(date, date2, "month");
    if (!d1 || !d2) {
      Modal.confirm({
        title: "Vérification...",
        content: `Les dates d'exécution ne sont définies dans la même période que celle de la fiche. Voulez-vous continuez?`,
        cancelText: "Annuler",
        okText: "Continuer",
        onOk: calculate,
      });
      return;
    }
    calculate();
  }

  async function calculate() {
    const { cnss, date, date1, date2, patronal, salary } = formik.values;
    const elements: any[] = [];
    elements.push(
      addSection(
        "Salaire de base",
        toNumber(salary),
        0,
        "",
        agent.uid,
        "",
        false,
        year,
        month,
        1,
      ),
    );
    let elem = addSectionTitle(sections, "indemnite", 10);
    if (!isEmpty(elem)) {
      elements.push(elem);
    }
    elem = addSectionTitle(sections, "retenu", 20);
    if (!isEmpty(elem)) {
      elements.push(elem);
    }
    elem = addSectionTitle(sections, "prime", 30);
    if (!isEmpty(elem)) {
      elements.push(elem);
    }
    elem = addSectionTitle(sections, "patronal", 40);
    if (!isEmpty(elem)) {
      elements.push(elem);
    }
    elem = addSectionTitle(sections, "other", 50);
    if (!isEmpty(elem)) {
      elements.push(elem);
    }
    const data: any = {
      year,
      month,
      personal: `/api/personals/${agent.uid}`,
      sections: elements,
      total,
    };
    setLoading(true);
    await payslipService
      .store(data)
      .then(async (response) => {
        await initData();
        notification.info({
          message: "Enregistrement de fiche",
          description: "Une fiche est créée avec succès",
        });
      })
      .catch((reason) => {
        const error = payslipService.getError(reason);
        notification.error({
          duration: 25,
          message: "Error",
          description: error,
        });
      });
    setLoading(false);
  }

  function addSectionTitle(sections: any[], cat: string, priority: number) {
    const { cnss, date, date1, date2, patronal, salary } = formik.values;
    const elements: any[] = [];
    let result: any = null;
    const compensations = sections.filter((p: any) => p.category === cat);
    if (compensations.length > 0) {
      const title = getTitleValue(cat);
      result = addSection(
        title,
        0,
        0,
        "title",
        agent.uid,
        cat,
        false,
        year,
        month,
        priority,
      );
      compensations.forEach((p: any, index: number) => {
        elements.push(
          addSection(
            p.title,
            toNumber(p.value),
            toNumber(p.rate) ?? 0,
            "content",
            agent.uid,
            "",
            p.isDeductible,
            year,
            month,
            priority + index + 1,
          ),
        );
      });
      if (elements.length > 0) {
        let som = 0;
        elements.forEach((p) => {
          if (p.isDeductible === true || p.isDeductible === false) {
            let rate = p.rate ?? 0;
            let amount = p.amount ?? 0;
            let res = 0;
            if (rate > 0 && amount > 0) {
              res = (rate * amount) / 100;
            } else if (amount > 0) {
              res = amount;
            }
            if (p.isDeductible) {
              som += -res;
            } else {
              som += res;
            }
          }
        });
        elements.push(
          addSection(
            `Total ${title}`,
            som,
            0,
            "total",
            agent.uid,
            "",
            undefined,
            year,
            month,
            priority + elements.length + 5,
          ),
        );
        result.subSections = elements;
      }
    }
    return result;
  }

  function addSection(
    designation: string,
    amount: number,
    rate: number,
    type: string,
    personal: string,
    category: string,
    isDeductible?: boolean,
    year?: number,
    month?: number,
    priority?: number,
  ) {
    const result = {
      designation,
      amount,
      rate,
      type,
      personal: `/api/personals/${personal}`,
      isDeductible: isDeductible,
      year,
      month,
      priority,
      category,
    };
    return result;
  }

  const getData = React.useCallback(async () => {
    const pers = agent;
    const n = dateHelpers.getDayInMonth(year, month);
    const date = dateHelpers.getDate(`${year}-${month}-01`, "YYYY-MM-DD");
    const date2 = dateHelpers.getDate(`${year}-${month}-${n}`, "YYYY-MM-DD");
    const dateMonth = dateHelpers.getDate(`${year}-${month}-01`, "YYYY-MM");
    formik.setValues({
      ...formik.values,
      salary: `${pers?.positionHeld?.salary ?? pers?.baseSalary ?? 0}`,
      date: dateMonth,
      date1: date,
      date2,
    });
  }, [agent, year, month]);

  React.useEffect(() => {
    getData();
  }, [agent, year, month]);

  async function initData() {
    const uid = params.id;
    if (isEmpty(uid)) {
      return;
    }
    setLoading(true);
    await personalService
      .get(uid + "")
      .then(async (response) => {
        const element = { ...response.data };
        element.baseSalary =
          element?.positionHeld?.salary ?? element?.baseSalary ?? 0;
        dispatch(setPersonal(element));
        await getpayslip(element);
      })
      .catch(() => {});
    await getAttendances();
    setLoading(false);
  }

  async function getAttendances() {
    const uid = params.id;
    const n = dateHelpers.getDayInMonth(year, month);
    const date1 = dateHelpers.getDate(`${year}-${month}-01`, "YYYY-MM-DD");
    const date2 = dateHelpers.getDate(`${year}-${month}-${n}`, "YYYY-MM-DD");
    let somDay = 0;
    let query = `agent=${uid}&order[recordedAt]=desc&`;
    query += `recordedAt[after]=${date1}&recordedAt[before]=${date2}`;
    console.log("Query", query);
    await attendanceService
      .getByKey(query)
      .then((response) => {
        const data = attendanceService.getData(response);
        setAttendances(data);
      })
      .catch(() => {});
    for (let index = 1; index <= n; index++) {
      const dt = dateHelpers.getDate(`${year}-${month}-${index}`, "d");
      const day = toNumber(dt) ?? -1;
      if (day >= 1 && day <= 5) {
        somDay += 1;
      }
    }
    setTotalWorkDay(somDay);
  }

  const onInit = React.useCallback(async () => {
    initData();
  }, [params, year, month]);

  React.useEffect(() => {
    onInit();
  }, [params, year, month]);

  function handleDelete(item: any, index: any) {
    Modal.confirm({
      title: "Suppression...",
      content: "Voulez-vous supprimer cette rubrique?",
      onOk() {
        let list = [...sections];
        list = list.filter((p, i) => i !== index);
        setSections(list);
      },
      okText: "Supprimer",
      cancelText: "Annuler",
      okType: "primary",
      okButtonProps: { danger: true },
    });
  }

  function handleUpdate(item: any, index: any) {
    setCurrentSection({ ...item, index });
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setSections((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onTotal = React.useCallback(() => {
    let som = 0;
    sections.forEach((p) => {
      if (p.isDeductible === true || p.isDeductible === false) {
        let rate = toNumber(p.rate) ?? 0;
        let amount = toNumber(p.value) ?? 0;
        let res = 0;
        if (rate > 0 && amount > 0) {
          res = (rate * amount) / 100;
        } else if (amount > 0) {
          res = amount;
        }
        if (p.isDeductible) {
          som += -res;
        } else {
          som += res;
        }
      }
    });
    som += agent?.positionHeld?.salary ?? agent.baseSalary ?? 0;
    setTotal(som);
  }, [sections]);

  React.useEffect(() => {
    onTotal();
  }, [sections]);

  return (
    <>
      <main>
        <Row gutter={32}>
          <Col lg={6} xs={24}>
            <Card>
              <Typography.Title
                level={2}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                {`Information de l'employé`}
              </Typography.Title>
              <div style={{ padding: "10px 0" }}>
                <Typography.Text strong>
                  {`${userHelper.getUsername(agent?.users)}`}
                </Typography.Text>
              </div>
              <DillInput
                enabled={true}
                formik={formik}
                type="month"
                name="date"
                label="Période de paiement"
              />
              <DillInput formik={formik} type="date" name="date1" label="Du" />
              <DillInput formik={formik} type="date" name="date2" label="Au" />
              <Divider />
              <DillInput
                formik={formik}
                enabled={false}
                type="num"
                name="salary"
                label="Salaire de base"
              />
              {totalWorkDay > 0 && (
                <div>
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`Jours de prestation: `}
                    <Typography.Text
                      type="secondary"
                      strong
                      style={{ fontSize: 14 }}
                    >
                      {`${attendances.length}`}
                    </Typography.Text>
                    {`/`}
                    <Typography.Text strong style={{ fontSize: 14 }}>
                      {`${totalWorkDay}`}
                    </Typography.Text>
                  </Typography.Text>
                </div>
              )}
            </Card>
          </Col>
          <Col lg={18} xs={24}>
            {isEmpty(payslip) && (
              <Card>
                <Typography.Title
                  level={2}
                  style={{ fontSize: 16, textAlign: "center" }}
                >
                  {`Autres information de paiement`}
                </Typography.Title>
                <div>
                  <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                  >
                    <SortableContext
                      // rowKey array
                      items={sections.map((i) => i.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Table
                        dataSource={sections}
                        className="table__1"
                        columns={[
                          { dataIndex: "title", title: "Désignation" },
                          { dataIndex: "value", title: "Base" },
                          { dataIndex: "rate", title: "Taux" },
                          {
                            title: "A retenir",
                            render(value, record, index) {
                              const s = toNumber(value.value);
                              const r = toNumber(value.rate);
                              let t = 0;
                              if (s > 0 && r > 0) {
                                t = (s * r) / 100;
                              } else if (r === 0) {
                                t = s;
                              }
                              return t;
                            },
                          },
                          {
                            dataIndex: "category",
                            title: "Catégorie",
                            render(value, record, index) {
                              return (
                                payslipCategories.find((p) => p.value === value)
                                  ?.title ?? ""
                              );
                            },
                          },
                          {
                            dataIndex: "isDeductible",
                            title: "Type",
                            render(value, record, index) {
                              return value ? (
                                <Tag color="red">{`A retrancer`}</Tag>
                              ) : (
                                <Tag color="gold-inverse">{`A ajouter`}</Tag>
                              );
                            },
                          },
                          {
                            dataIndex: "",
                            title: "Action",
                            render(value, record, index) {
                              return (
                                <div
                                  style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    gap: 4,
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <Button
                                      onClick={() => handleDelete(value, index)}
                                      type="primary"
                                      danger
                                      size="small"
                                      shape="circle"
                                    >
                                      <CloseOutlined />
                                    </Button>
                                  </div>
                                  <div>
                                    <Button
                                      onClick={() => handleUpdate(value, index)}
                                      type="primary"
                                      size="small"
                                      shape="circle"
                                    >
                                      <EditFilled />
                                    </Button>
                                  </div>
                                </div>
                              );
                            },
                          },
                        ]}
                        pagination={false}
                        style={{ marginBottom: 10 }}
                        components={{
                          body: {
                            row: RowSection,
                          },
                        }}
                        rowKey="uid"
                      />
                    </SortableContext>
                  </DndContext>
                  <AddSection
                    current={currentSection}
                    setCurrent={setCurrentSection}
                    elements={sections}
                    setElements={setSections}
                  />
                  <div>
                    <Typography.Text style={{ fontSize: 30 }}>
                      {`SALAIRE NET: `}
                      <Typography.Text strong style={{ fontSize: 30 }}>
                        {`${total ?? 0} $`}
                      </Typography.Text>
                    </Typography.Text>
                  </div>
                </div>
              </Card>
            )}
            {!isEmpty(payslip) && (
              <Card>
                <PayslipCreation />
              </Card>
            )}
          </Col>
          <Col lg={6} xs={24} style={{ display: "none" }}>
            <Card>
              <Typography.Title
                level={2}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                {`Retenue de salaire`}
              </Typography.Title>
              <div>
                <DillInput
                  formik={formik}
                  type="num"
                  name="cnss"
                  placeholder="En %"
                  label="CNSS"
                />
                <DillInput
                  formik={formik}
                  type="num"
                  name="patronal"
                  placeholder="En %"
                  label="Quote patronale"
                />
              </div>
            </Card>
          </Col>
        </Row>
        {isEmpty(payslip) && (
          <div
            style={{
              display: "flex",
              padding: 50,
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              disabled={!(total > 0)}
              onClick={handleSubmit}
              type="primary"
              shape="round"
            >
              {`Lancer la création`}
            </Button>
          </div>
        )}
        {/*<PayslipCreation />*/}
      </main>
      <LoaderComponent loading={loading} />
    </>
  );
}
