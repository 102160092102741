import { Button, Table } from "antd";
import React from "react";
import { CreateDepartment } from "./createDepartment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Icon } from "@iconify/react";
import { haveRightRoles } from "../../utils/helper/title";

export function OrganizationDepartmentView() {
  const departments = useSelector((state: RootState) => state.department.all);
  const [current, setCurrent] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);
  const roles = useSelector((state: RootState) => state.user.roles);

  function handleOpen(value: any) {
    setCurrent(value);
    setOpen(true);
  }
  return (
    <section>
      <CreateDepartment />
      <Table
        columns={[
          { dataIndex: "name", title: "Département" },
          {
            dataIndex: "personals",
            title: "agents",
            render(value, record, index) {
              return value.length;
            },
          },
          {
            render(value, record, index) {
              return (
                <div>
                  <Button onClick={() => handleOpen(value)} shape="circle">
                    <Icon icon="el:edit" />
                  </Button>
                </div>
              );
            },
            hidden: !haveRightRoles(["ROLE_ADMIN", "ROLE_DEV"], roles),
          },
          // { dataIndex: "action", title: "Actions" },
        ]}
        dataSource={departments}
        className="table__1"
        pagination={false}
      />
      <CreateDepartment
        edit
        item={current}
        setVisible={setOpen}
        visible={open}
      />
    </section>
  );
}
