import { Col, Row } from "antd";
import React from "react";
import {
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../components/input";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { isEmpty, random, toUpper, upperFirst } from "lodash";
//@ts-ignore
import pwd from "secure-random-password";

export function CreateAgentGrade(props: { formik: any; edit: boolean }) {
  const departments = useSelector((state: RootState) => state.department.all);
  const grades = useSelector((state: RootState) => state.grade.all);
  const users = useSelector((state: RootState) => state.user.all);
  const [departmentSelections, setDepartmentSelections] = React.useState<any[]>(
    [],
  );
  const [gradeSelections, setGradeSelections] = React.useState<any[]>([]);
  const [personals, setPersonals] = React.useState<any[]>([]);

  const onInit = React.useCallback(async () => {
    getDepartments();
    getGrades();
    getPersonals();
  }, [departments, grades]);

  function getDepartments() {
    const elements: any[] = [];
    departments.forEach((p) => {
      elements.push({
        value: p.uid,
        title: p.name,
      });
    });
    elements.unshift({ value: "", title: "Séléctionner un département" });
    setDepartmentSelections(elements);
  }

  function getGrades() {
    const elements: any[] = [];
    grades.forEach((p) => {
      elements.push({
        value: p.uid,
        title: p.name,
      });
    });
    elements.unshift({ value: "", title: "Séléctionner une fonction" });
    setGradeSelections(elements);
  }

  function getPersonals() {
    const elements: any[] = [];
    users.forEach((p) => {
      if (!isEmpty(p.personals)) {
        elements.push({
          value: p.personals.uid,
          title: `${toUpper(p.lastName)} ${toUpper(p.middleName)} ${upperFirst(p.firstName)}`,
        });
      }
    });
    elements.unshift({ value: "", title: "Séléctionner un superviseur" });
    setPersonals(elements);
  }

  React.useEffect(() => {
    onInit();
  }, [departments, grades]);

  const onPassword = React.useCallback(() => {
    if (props.formik.values.createAccount) {
      const password = pwd.randomPassword({ length: 12 });
      //const password = random(10000, 9000000, false);
      props.formik?.setFieldValue("password", password);
    }
  }, [props.formik.values.createAccount]);

  React.useEffect(() => {
    onPassword();
  }, [props.formik.values.createAccount]);

  return (
    <div>
      <Row gutter={32}>
        <Col lg={12} xs={24} md={12}>
          <DillSelect
            formik={props.formik}
            name="department"
            label="Département"
            list={departmentSelections}
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="service" label="Service" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillSelect
            formik={props.formik}
            name="grade"
            label="Fonction"
            list={gradeSelections}
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillSelect
            formik={props.formik}
            name="supervisor"
            label="Superviseur"
            list={personals}
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput
            formik={props.formik}
            name="grossSalary"
            label="Salaire de base"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput
            formik={props.formik}
            name="ccnssId"
            type="text"
            label="Numéro CNSS"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput
            formik={props.formik}
            name="matricule"
            label="Matricule de l'agent"
            type="text"
          />
        </Col>
        {!props.edit && (
          <Col lg={12} xs={24} md={12}>
            <DillRadio
              formik={props.formik}
              name="createAccount"
              label="Créer un compte utilisateur"
              list={[
                { value: true, title: "Oui" },
                { value: false, title: "Non" },
              ]}
            />
          </Col>
        )}
        {props.formik.values.createAccount && !props.edit && (
          <Col lg={12} xs={24} md={12}>
            <DillInput
              formik={props.formik}
              name="password"
              label="Mot de passe"
              enabled={false}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}
