import React from "react";
//@ts-ignore
import html2pdf from "../../../node_modules/html2pdf.js/dist/html2pdf.bundle.min.js";
import {
  Button,
  Col,
  Divider,
  Drawer,
  List,
  Row,
  Table,
  Typography,
} from "antd";
import { colorPrimary } from "../../utils/theme/color";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.js";
import userHelper from "../../utils/helper/userHelper";
import dateHelpers from "../../utils/helper/dateHelpers";
import { isEmpty } from "lodash";

export function PayslipCreation() {
  const print = React.useRef(null);
  const [data, setData] = React.useState<any[]>([]);
  const [infos, setInfos] = React.useState<any[]>([]);
  const [sections, setSections] = React.useState<any[]>([]);
  const agent = useSelector((state: RootState) => state.personal.current);
  const [amount, setAmount] = React.useState(0);
  const payslip = useSelector((state: RootState) => state.payslip.current);

  function onCreate() {
    const element = print.current;
    const opt = {
      filename: `${userHelper.getUsername(agent.users)}(${payslip.month < 10 ? "0" + payslip.month : payslip.month}-${payslip.year})`,
    };
    //@ts-ignore
    html2pdf(element)
      .set(opt)
      .save(
        `${userHelper.getUsername(agent.users)}(${payslip.month < 10 ? "0" + payslip.month : payslip.month}-${payslip.year})`,
      );
  }

  //create()

  React.useEffect(() => {
    onPrint();
  }, [print]);

  const onInit = React.useCallback(() => {
    if (!isEmpty(payslip.sections)) {
      let elems: any[] = [];
      [...payslip.sections]
        .sort(
          (a, b) => (b.subSections?.length ?? 0) - (a.subSections?.length ?? 0),
        )
        .sort((a, b) => a.priority - b.priority)
        .forEach((p: any) => {
          const list = addSection(p, elems);
          list.forEach((q) => {
            elems.push(q);
          });
        });
      //elems = elems.sort((a, b) => a.priority - b.priority);
      setData(elems);
    }
    getProfile();
  }, [agent, payslip]);

  function addSection(item: any, list: any[]) {
    const items: any[] = [];
    const index = list.findIndex((p) => p.uid === item.uid);
    if (index === -1) {
      let base = item.amount;
      let rate = item.rate;
      let total = 0;
      if (base > 0 && rate > 0) {
        total = (base * rate) / 100;
      } else if (rate === 0) {
        total = base;
      }
      const v: any = { ...item, base, total };
      if (item.subSections?.length > 0) {
        v.isTitle = true;
        v.total = "";
        v.base = "";
        v.rate = "";
      }
      items.push(v);
      item.subSections?.forEach((p: any) => {
        const elements = addSection(p, list);
        elements.forEach((q) => {
          items.push(q);
        });
      });
    }
    return items;
  }

  React.useEffect(() => {
    onInit();
  }, [agent, payslip]);

  function getProfile() {
    const elements: any[] = [];
    elements.push({
      value: userHelper.getUsername(agent.users),
      title: "Nom & postnom",
    });
    elements.push({
      value: agent.grade,
      title: "Fonction",
    });
    elements.push({
      value: "",
      title: "Matricule",
    });
    elements.push({
      value: "",
      title: "Grade",
    });
    /*elements.push({
      value: "21 Mai 2022",
      title: "Date d'adhésion",
    });*/
    /*elements.push({
      value: dateHelpers
        .getDate(`${payslip.year}-${payslip.month}-01`, "MMMM YYYY")
        ?.toUpperCase(),
      title: "Période de paie",
    });*/
    /*elements.push({
      value: dateHelpers
        .getDate(payslip.updateAt, "DD MMMM YYYY")
        ?.toUpperCase(),
      title: "Date de paie",
    });*/
    setInfos(elements);
  }

  const onPrint = React.useCallback(() => {
    if (print.current !== null) {
      const element = print.current;
      //@ts-ignore
      //html2pdf(element);
    }
  }, [print]);

  const renderItem = (item: any) => {
    return (
      <List.Item style={{ padding: 2 }}>
        <Typography.Text
          style={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
        >
          <span className="title__span">{`${item.title}`}</span>
          <Typography.Text style={{ fontSize: 12 }} className="title__content">
            {item.value}
          </Typography.Text>
        </Typography.Text>
      </List.Item>
    );
  };

  return (
    <div>
      <div>
        <Button onClick={onCreate} className="btn btn-info" type="primary">
          {`Télécharger`}
        </Button>
      </div>
      <div id="element-to-print" className="element-to-print" ref={print}>
        <section id="header">
          <Row align="top" gutter={24} wrap={false}>
            <Col>
              <img
                style={{
                  width: "100px",
                  display: "inline-block",
                }}
                src={require("../../assets/images/logo-cami.png")}
                alt=""
              />
            </Col>
            <Col>
              <Typography.Text
                style={{ textTransform: "uppercase", display: "block" }}
              >
                {`Cadastre minier`}
              </Typography.Text>
              <Typography.Text style={{ display: "block" }}>
                {`Direction Administrative`}
              </Typography.Text>
              <Typography.Text style={{ display: "block" }}>
                {`Département des Ressources Humaines`}
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", textDecoration: "underline" }}
              >
                {`Service de Rémunération`}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{ display: "block" }}>
                {`Croisement des av Mpolo Maurice`}
              </Typography.Text>
              <Typography.Text style={{ display: "block" }}>
                {`et Kasa-vubu/Gombe`}
              </Typography.Text>
              <Typography.Text style={{ display: "block" }}>
                {`BP 7387, Kinshasa 1`}
              </Typography.Text>
            </Col>
          </Row>
          <Divider
            style={{
              margin: "5px 0",
              background: colorPrimary,
              display: "none",
            }}
          />
        </section>
        <section style={{ paddingBottom: 10, paddingTop: 20 }}>
          <Typography.Title
            level={1}
            style={{
              fontSize: 16,
              textDecoration: "underline",
              textTransform: "uppercase",
              textAlign: "center",
            }}
            //type="success"
          >
            {`Feuille de paie de l'agent`}
          </Typography.Title>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <Typography.Text strong className="title__span">
              Mois
            </Typography.Text>
            <Typography.Text strong style={{ paddingLeft: 10 }}>
              {dateHelpers
                .getDate(`${payslip.year}-${payslip.month}-01`, "MMMM YYYY")
                ?.toUpperCase()}
            </Typography.Text>
          </div>
          <Row>
            <Col flex={1}>
              <List
                grid={{ column: 2 }}
                renderItem={renderItem}
                dataSource={infos}
              />
            </Col>
            {/*<Col flex={1} style={{ textAlign: "center" }}>
              <Typography.Text strong>{`Net à payer`}</Typography.Text>
              <Typography.Title style={{ margin: 0 }}>
                {`${payslip.total ?? 0} $`}
              </Typography.Title>
              </Col>*/}
          </Row>
        </section>
        <section>
          <Table
            className="table__1"
            columns={[
              {
                title: "Désignation",
                render(value, record, index) {
                  return value.isTitle ? (
                    <Typography.Text strong>
                      {value.designation}
                    </Typography.Text>
                  ) : (
                    value.designation
                  );
                },
              },
              {
                title: "Montant de base",
                render(value, record, index) {
                  if (value.type === "total") {
                    return "";
                  } else {
                    const amount = value.base;
                    return amount == 0 ? "" : amount > 0 ? amount : -amount;
                  }
                },
              },
              {
                dataIndex: "rate",
                title: "Taux",
                render(value, record, index) {
                  return value > 0 ? `${value} %` : "";
                },
              },
              {
                title: "Montant final",
                render(value, record, index) {
                  const amount = value.total;
                  if (value.type === "total") {
                    return (
                      <Typography.Text
                        strong
                        type={amount < 0 ? "danger" : undefined}
                      >
                        {amount == 0 ? "" : amount > 0 ? amount : -amount}
                      </Typography.Text>
                    );
                  } else {
                    return (
                      <Typography.Text
                        type={value.isDeductible ? "danger" : undefined}
                      >
                        {amount == 0 ? "" : amount > 0 ? amount : -amount}
                      </Typography.Text>
                    );
                  }
                },
              },
            ]}
            dataSource={data.sort((a, b) => a.priority - b.priority)}
            pagination={false}
          />
        </section>
        <section className="footer">
          <div
            style={{
              display: "flex",
              alignSelf: "end",
              width: "auto",
              clear: "left",
              float: "right",
              alignItems: "center",
              border: "solid 1px",
            }}
          >
            <Typography.Text
              strong
              style={{ padding: "0 10px" }}
            >{`Net à payer en USD`}</Typography.Text>
            <Typography.Text
              strong
              style={{
                margin: 0,
                padding: "0 10px",
                border: "solid 1px",
                borderTop: "none",
                borderBottom: "none",
                borderRight: "none",
              }}
            >
              {`${payslip.total ?? 0}`}
            </Typography.Text>
          </div>
          <Row align="bottom" style={{ clear: "right" }}>
            <Col flex={1}>
              <div>
                <Typography.Text>
                  {`Fait à `}
                  <span style={{ paddingLeft: 100 }}></span>
                  {`, le ${dateHelpers.getDate(payslip.updateAt, "DD/MM/YYYY")}`}
                </Typography.Text>
              </div>
              <Typography.Text style={{ textTransform: "inherit" }}>
                {`Chef de Service Rémunération`}
              </Typography.Text>
            </Col>
            <Col>
              <div style={{ paddingTop: 15 }}>
                <Typography.Text style={{ textTransform: "inherit" }}>
                  {`Signature de l'Agent`}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
}
