import { Typography } from "antd";
import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  MenuItemStyles,
  menuClasses,
} from "react-pro-sidebar";
import { getRoutePathOfComponent, routes } from "../routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarHeader } from "./sidebarHeader";
import { SidebarFooter } from "./sidebarFooter";
import { isEmpty } from "lodash";
import { Icon } from "@iconify/react";
import authService from "../services/authService";
import userHelper from "../utils/helper/userHelper";

export type Theme = "light" | "dark";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export function SidebarMenu(props: {
  collapsed?: boolean;
  theme: Theme;
  toggled: boolean;
  rtl: boolean;
  hasImage: boolean;
  setBroken: (v: boolean) => void;
  setToggled: (v: boolean) => void;
}) {
  const { collapsed, hasImage, rtl, theme, toggled, setBroken, setToggled } =
    props;
  const location = useLocation();
  const { pathname } = location;

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1,
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1,
        ),
        color: themes[theme].menu.hover.color,
      },
      [`&.ps-active`]: {
        backgroundColor: "#13395e",
        color: "#b6c8d9",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
      image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
      rtl={rtl}
      breakPoint="md"
      backgroundColor={hexToRgba(
        themes[theme].sidebar.backgroundColor,
        hasImage ? 0.9 : 1,
      )}
      rootStyles={{
        color: themes[theme].sidebar.color,
        height: "100%",
        position: "sticky",
        zIndex: 999,
        left: 0,
        top: 0,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <SidebarHeader
          rtl={rtl}
          style={{ marginBottom: "24px", marginTop: "16px" }}
        />
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <Typography.Text
              style={{
                opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                fontWeight: "600",
                fontSize: 14,
                color: themes[theme].sidebar.color,
              }}
            >
              General
            </Typography.Text>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            {routes
              .filter((p) => p.layout === "admin" && p.isMenu)
              .map((p, index) => (
                <MenuItem
                  component={
                    <Link
                      title={p.title}
                      to={getRoutePathOfComponent("", p.path)}
                    />
                  }
                  title={p.title}
                  icon={
                    !isEmpty(p.icon) ? (
                      <Icon icon={p.icon ?? ""} style={{ fontSize: 22 }} />
                    ) : undefined
                  }
                  active={
                    getRoutePathOfComponent("", p.path) ===
                    getRoutePathOfComponent("", pathname)
                  }
                >
                  {p.label}
                </MenuItem>
              ))}
          </Menu>
          <div
            style={{
              padding: "0 24px",
              marginBottom: "8px",
              marginTop: "32px",
            }}
          >
            <Typography.Title
              style={{
                opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
                fontWeight: "600",
                fontSize: 16,
                color: themes[theme].sidebar.color,
              }}
            >
              Extra
            </Typography.Title>
          </div>
          {/**other menus */}
        </div>
        <SidebarFooter collapsed={collapsed} />
      </div>
    </Sidebar>
  );
}
