import React from "react";
import { Route, Routes } from "react-router-dom";
import { getRoutePathOfComponent, routes } from "../routes";
import { Page404Screen } from "./404Page";

export function HomeLayout() {
  return (
    <main>
      <Routes>
        {[...routes]
          .filter((p) => p.layout === "home")
          .map((p, index) => (
            <Route
              path={getRoutePathOfComponent("", p.path)}
              element={<p.component />}
            />
          ))}
        <Route path="*" element={<Page404Screen />} />
      </Routes>
    </main>
  );
}
