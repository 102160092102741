import React from "react";
import { Layout } from "antd";

const SideBar = (props: { menu: any }) => {
  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      {props.menu}
    </Layout.Sider>
  );
};
export { SideBar };
