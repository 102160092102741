import { Avatar, Button, Col, List, Popover, Row, Table } from "antd";
import React from "react";
import { CardPerson } from "../components/cardPerson";
import { CreateAgent } from "./agent/create";
import { useNavigate } from "react-router-dom";
import { random } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import userHelper from "../utils/helper/userHelper";
import {
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  MoreOutlined,
  UserOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";
import { getFileURL, haveRightRoles } from "../utils/helper/title";
import { Icon } from "@iconify/react";
import { CreateUser } from "./agent/createUser";

export function AgentScreen() {
  const navigate = useNavigate();
  const users = useSelector((state: RootState) => state.user.all);
  const roles = useSelector((state: RootState) => state.user.roles);
  const [open, setOpen] = React.useState(false);
  const [openCreation, setOpenCreation] = React.useState(false);
  const [current, setCurrent] = React.useState<any>({});

  function handleOpen(item: any) {
    navigate(`/agents/${item.personals?.uid}/view`);
  }

  function handleUpdate(item: any) {
    setCurrent(item);
    setOpen(true);
  }

  function handleCreation(item: any) {
    setCurrent(item);
    setOpenCreation(true);
  }

  const RenderMenu = (props: { item: any }) => {
    return (
      <div>
        <Button type="link" className="btn--menu">
          {`Voir`}
        </Button>
        <Button type="link" className="btn--menu">
          {`Modifier`}
        </Button>
      </div>
    );
  };

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <CardPerson
          title={userHelper.getUsername(item)}
          subTitle={item?.personals?.grade}
          actions={[
            {
              label: "Voir",
              onPress: () => handleOpen(item),
            },
            { label: "Congés" },
          ]}
        />
      </List.Item>
    );
  };
  return (
    <>
      <main>
        <Row gutter={16} style={{ padding: 18 }}>
          <Col>
            <CreateAgent />
          </Col>
        </Row>
        {/*<List
          dataSource={users}
          renderItem={renderItem}
          grid={{ lg: 2, xs: 1, xl: 3, md: 1, gutter: 16 }}
  />*/}
        <Table
          className="table__1"
          dataSource={users}
          columns={[
            {
              title: "",
              render(value, record, index) {
                return (
                  <Avatar
                    size="large"
                    className="img--user-2"
                    src={getFileURL(value.photo)}
                  >
                    <UserOutlined />
                  </Avatar>
                );
              },
            },
            {
              title: "Nom de l'agent",
              render(value, record, index) {
                return userHelper.getUsername(value);
              },
            },
            {
              title: "Fonction",
              dataIndex: "personals",
              render(value, record, index) {
                return value?.positionHeld?.name ?? "";
              },
            },
            {
              title: "Département",
              dataIndex: "personals",
              render(value, record, index) {
                return value?.department?.name;
              },
            },
            {
              title: "Action",
              render(value, record, index) {
                return (
                  <Row gutter={12}>
                    <Col>
                      <Button
                        onClick={() => handleOpen(value)}
                        shape="circle"
                        type="primary"
                      >
                        <EyeOutlined />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => handleUpdate(value)}
                        type="default"
                        shape="circle"
                      >
                        <EditOutlined />
                      </Button>
                    </Col>
                    {!userHelper.isUser(value) &&
                      haveRightRoles(["ROLE_ADMIN", "ROLE_RH"], roles) && (
                        <Col>
                          <Button
                            onClick={() => handleCreation(value)}
                            type="primary"
                            danger
                            shape="circle"
                            title="Créer un compte utilisateur"
                          >
                            <Icon
                              style={{ fontSize: 20 }}
                              icon="mdi:account-plus"
                            />
                          </Button>
                        </Col>
                      )}
                  </Row>
                );
              },
            },
          ]}
        />
      </main>
      <CreateAgent edit item={current} open={open} setOpen={setOpen} />
      <CreateUser
        user={current}
        open={openCreation}
        setOpen={setOpenCreation}
      />
    </>
  );
}
