import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import React from "react";
import userHelper from "../../utils/helper/userHelper";
import dateHelpers from "../../utils/helper/dateHelpers";
import { getLeaveStatus } from "../../utils/helper/title";
import { CalendarAccept } from "./button/accept";

export function CalendarView(props: {
  current: any;
  open: boolean;
  setOpen: (v: boolean) => void;
}) {
  const { current, open, setOpen } = props;
  const [status, setStatus] = React.useState("");

  function handleClose() {
    setOpen(false);
  }

  const onInit = React.useCallback(() => {
    const { badge, result } = getLeaveStatus(current);
    setStatus(badge);
  }, [current]);

  React.useEffect(() => {
    onInit();
  }, [current]);

  return (
    <>
      <Modal
        title={`${userHelper.getUsername(current?.personal?.users ?? {})}`}
        open={open}
        onCancel={handleClose}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        centered
      >
        <div>
          <Typography.Text
            strong
            style={{
              textAlign: "center",
              display: "block",
              textTransform: "uppercase",
            }}
          >
            {current?.category}
          </Typography.Text>
          <Divider />
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {current?.description}
          </Typography.Paragraph>
          <div style={{ display: "flex", alignItems: "center", columnGap: 20 }}>
            <Typography.Text strong>{`Date de début: `}</Typography.Text>
            <Typography.Text style={{ textTransform: "uppercase" }}>
              {dateHelpers.getDate(current?.startAt, "dddd DD MMMM YYYY")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", alignItems: "center", columnGap: 20 }}>
            <Typography.Text strong>{`Date de fin: `}</Typography.Text>
            <Typography.Text style={{ textTransform: "uppercase" }}>
              {dateHelpers.getDate(current?.finishAt, "dddd DD MMMM YYYY")}
            </Typography.Text>
          </div>
          <Divider />
          <Row justify="end">
            {status === "in_pending" && (
              <Col>
                <CalendarAccept current={current} setOpen={setOpen} />
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
}
