import { Button, Modal } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput, DillRadio, DillSelect } from "../../components/input";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { payslipCategories } from "../../utils/helper/title";

export function AddSection(props: {
  current?: any;
  setCurrent?: any;
  setElements: any;
  elements: any[];
  onOpen?: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      title: "",
      value: "0",
      rate: "0",
      isDeductible: false,
      category: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Champ obligatoire"),
      value: yup
        .number()
        .required("Champ obligatoire")
        .typeError("Champ numérique")
        .min(0, "Valeur minimale est 0"),
      rate: yup
        .number()
        //.required("Champ obligatoire")
        .typeError("Champ numérique")
        .min(0, "Valeur minimale est 0")
        .max(100, "Valeur maximale est 100"),
    }),
    onSubmit,
  });

  const onCurrent = React.useCallback(() => {
    if (!isEmpty(props.current)) {
      formik.setValues({
        ...formik.values,
        title: props.current?.title,
        value: props.current?.value,
        isDeductible: props.current?.isDeductible,
        category: props.current?.category,
        rate: props.current?.rate,
      });
      setOpen(true);
    }
  }, [props.current]);

  function getRef() {
    let uid = 0;
    let rep = false;
    do {
      const v = props.elements.find((p) => p.uid == uid);
      rep = isEmpty(v);
      if (rep) {
        uid += 1;
      }
    } while (!rep);
    return `${uid}`;
  }

  React.useEffect(() => {
    onCurrent();
  }, [props.current]);

  function onSubmit() {
    const { category, isDeductible, title, value, rate } = formik.values;
    const elem: any = {
      title,
      value,
      isDeductible,
      category,
      rate: isEmpty(rate) ? "0" : rate,
    };
    let list: any[] = [...props.elements];
    if (isEmpty(props.current)) {
      elem.uid = getRef();
      list.push(elem);
      props.setElements(list);
    } else {
      elem.uid = props.current?.uid;
      list.splice(props.current?.index, 1, elem);
      props.setElements(list);
      props.setCurrent({});
    }
    setOpen(false);
    formik.resetForm();
  }

  function handleOpen() {
    formik.resetForm();
    setOpen(true);
  }

  function handleClose() {
    formik.resetForm();
    setOpen(false);
  }

  function handleSubmit() {
    formik.submitForm();
  }

  React.useEffect(() => {
    const elements = [...payslipCategories];
    elements.unshift({ value: "", title: "Séléctionner une catégorie" });
    setList(elements);
  }, []);

  return (
    <>
      <Button onClick={handleOpen}>{`Ajouter une rubrique`}</Button>
      <Modal
        onOk={handleSubmit}
        okText="Valider"
        cancelText="Annuler"
        onCancel={handleClose}
        title="Ajouter une rubrique"
        open={open}
      >
        <div>
          <DillInput formik={formik} name="title" label={"Désignation"} />
          <DillInput formik={formik} name="value" label={"Montant"} />
          <DillInput formik={formik} name="rate" label={"Taux"} />
          <DillRadio
            inline
            formik={formik}
            name="isDeductible"
            list={[
              { value: false, title: "NON" },
              { value: undefined, title: "NEUTRE" },
              { value: true, title: "OUI" },
            ]}
            label={"Déductible ?"}
          />
          <DillSelect
            list={list}
            formik={formik}
            name="category"
            label={"Catégorie"}
          />
        </div>
      </Modal>
    </>
  );
}
