import React from "react";
import { Menu } from "antd";
const TopicMenu = (props: {
  topics: any[];
  selectedKey: string;
  changeSelectedKey?: (e?: any) => void;
}) => {
  const styledTopics: any[] = [];
  props.topics.forEach((topic, index) =>
    styledTopics.push(
      <Menu.Item key={index} onClick={props.changeSelectedKey}>
        {topic?.label}
      </Menu.Item>,
    ),
  );

  return (
    <Menu mode="inline" selectedKeys={[props.selectedKey]}>
      {styledTopics}
    </Menu>
  );
};
export { TopicMenu };
