import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import dateHelpers from "../../utils/helper/dateHelpers";
import { CreateCalendar } from "../calendar/create";
import { Col, Divider, Row, Tag, Typography } from "antd";
import { getLeaveStatus } from "../../utils/helper/title";
import { colorPrimary } from "../../utils/theme/color";

const localizer = momentLocalizer(moment);

export function AgentCalendar(props: { leaves: any[]; agent: any }) {
  const { leaves } = props;
  const [events, setEvents] = React.useState<any[]>([]);
  const [inPending, setInPending] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  const onInit = React.useCallback(() => {
    const elements: any[] = [];
    let pendingCount = 0;
    let progressCount = 0;
    leaves.forEach((p) => {
      const start = dateHelpers.getDate(p.startAt, "YYYY-MM-DD");
      const end = dateHelpers.getDate(p.finishAt, "YYYY-MM-DD");
      let color = colorPrimary;
      const { badge } = getLeaveStatus(p);
      if (badge === "in_progress") {
        progressCount++;
        color = "green";
      } else if (badge === "in_pending") {
        pendingCount++;
        color = "gold";
      }
      elements.push({
        start,
        end,
        eventClasses: "optionalEvent",
        title: `${p.category}`,
        description: "This is a test description of an event",
        uid: p.uid,
        color,
      });
    });
    setInPending(pendingCount);
    setProgress(progressCount);
    setEvents(elements);
  }, [leaves]);

  React.useEffect(() => {
    onInit();
  }, [leaves]);

  return (
    <section>
      <div style={{ display: "flex", justifyContent: "end", padding: 10 }}>
        <CreateCalendar agent={props.agent} target="agent" />
      </div>
      <Typography.Title style={{ textAlign: "center" }} level={2}>
        {`Calendrier de congé`}
      </Typography.Title>
      <Divider />
      <Row gutter={16}>
        <Col>
          <div style={{ display: "flex", alignItems: "center", columnGap: 5 }}>
            <span
              style={{
                background: "gold",
                width: 20,
                height: 20,
                display: "inline-block",
                borderRadius: "100%",
              }}
            />
            <span>
              {inPending > 0
                ? `${inPending} congé${inPending > 1 ? "s" : ""} en attente`
                : `Aucun congé en attente`}
            </span>
          </div>
        </Col>
        <Col>
          <div style={{ display: "flex", alignItems: "center", columnGap: 5 }}>
            <span
              style={{
                background: "green",
                width: 20,
                height: 20,
                display: "inline-block",
                borderRadius: "100%",
              }}
            />
            <span>
              {progress > 0
                ? `${progress} congé${progress > 1 ? "s" : ""} à venir`
                : `Aucun congé à venir`}
            </span>
          </div>
        </Col>
      </Row>
      <Divider />
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        backgroundEvents={["red", "gold", "teal"]}
        resourceIdAccessor="uid"
        views={["month", "week", "agenda"]}
        messages={{
          month: "Mois",
          week: "Semaine",
          today: "Aujourd'hui",
          next: "Suivant",
          previous: "Retour",
        }}
        showAllEvents
        style={{ height: 500 }}
        components={{
          event: (v: any) => {
            const color = v?.event?.color ?? colorPrimary;
            return (
              <div style={{ background: color, borderColor: color }}>
                {v?.event?.title}
              </div>
            );
          },
        }}
      />
    </section>
  );
}
