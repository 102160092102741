import { List } from "antd";
import React from "react";
import { MenuCard } from "../../components/menu";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import { haveRightRoles } from "../../utils/helper/title";

export function DashboardMenu() {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.profile);
  const roles = useSelector((state: RootState) => state.user.roles);
  const [menus, setMenus] = React.useState<any[]>([]);

  const renderItem = (info: any) => {
    return (
      <List.Item>
        <MenuCard
          icon={info.icon}
          description={info.description}
          title={info.title}
          onClick={info.onPress}
        />
      </List.Item>
    );
  };

  function handleUrl(url: string) {
    navigate(url);
  }

  const onInit = React.useCallback(() => {
    setMenus([
      {
        title: "Les agents",
        description: `Liste d'agents dans l'entreprise; possibilité d'ajouter d'autres`,
        onPress: () => handleUrl("/agents"),
        icon: <Icon icon="material-symbols-light:support-agent" />,
        enabled: true,
      },
      {
        title: "Le calendier des vacances",
        description:
          "Liste de toutes les vacances dans l'entreprise, possibilité de d'enregistrer",
        onPress: () => handleUrl("/calendar"),
        icon: <Icon icon="twemoji:calendar" />,
        enabled: haveRightRoles(["ROLE_DEV", "ROLE_ADMIN", "ROLE_RH"], roles),
      },
      {
        title: "Organisation",
        description:
          "Présentation de l'entreprise, gestion des départements, récapitulatifs des services, liste de présence, gestion des foncions et salaires associés",
        onPress: () => handleUrl("/organization"),
        icon: <Icon icon="eos-icons:organization" />,
        enabled: true,
      }, //département(nombre d'agents), service(nbre), présentation (agents, responsabilité),
      {
        title: "Mes vacances",
        description:
          "Vue sur vos vacances, états de vos demandes, la possibilité de soumettre des nouvelles demandes, calendrier de vos vacances",
        onPress: () => {},
        icon: <Icon icon="clarity:on-holiday-line" />,
        enabled: !isEmpty(user?.personals),
        //mes congés (calendrier, tableau)
      },
      {
        title: "Droits et accès",
        description:
          "Gestion des droits et accès dans l'application. Présentation des règles de gestion, les périmètre d'accès de chaque acteur dans l'application.",
        onPress: () => {},
        icon: <Icon icon="codicon:law" />,
        enabled: true,
      },
      {
        title: "Mes fiches de paie",
        description:
          "La liste de toutes mes feuilles de paie, possibilité de recherche rapide.",
        onPress: () => handleUrl("/mypayslips"),
        icon: <Icon icon="noto:money-bag" />,
        enabled: !isEmpty(user?.personals),
      },
      {
        title: "Configuration",
        description: "Réglage général de l'application. ",
        onPress: () => {},
        icon: <Icon icon="tdesign:setting-1" />,
        enabled: true,
        //devise, theme, importer la fiche de présence
      },
    ]);
  }, [user]);

  React.useEffect(() => {
    onInit();
  }, [user]);
  return (
    <section>
      <List
        dataSource={menus.filter((p) => p.enabled)}
        renderItem={renderItem}
        grid={{ xs: 1, xl: 4, md: 2, sm: 2, lg: 3, xxl: 4, gutter: 16 }}
        className="list__menu"
      />
    </section>
  );
}
