import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface studentData {
  profil: any;
  current: any;
  list: any[];
  all: any[];
  teachers: any[];
  readings: any[];
  lessons: any[]; //foreground lesson
  video: any; //foreground video
  poster: any; //foreground video poster
}

const initialState: studentData = {
  profil: {},
  current: {},
  list: [],
  teachers: [],
  readings: [],
  lessons: [],
  video: null,
  poster: null,
  all: [],
};

export const studentSlice = createSlice({
  name: "payslips",
  initialState,
  reducers: {
    setPayslip: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setPayslips: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllPayslips: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllPayslips, setPayslip, setPayslips } = studentSlice.actions;
export default studentSlice.reducer;
