import { Button, Card, Form, Input, Popconfirm, Table } from "antd";
import React from "react";
import type { GetRef } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helper/userHelper";
import personalService from "../../services/personalService";
import { isEmpty, toNumber } from "lodash";
import userService from "../../services/userService";
import { haveRightRoles } from "../../utils/helper/title";

type InputRef = GetRef<typeof Input>;
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<InputRef>(null);
  const form = React.useContext(EditableContext)!;
  const roles = useSelector((state: RootState) => state.user.roles);

  React.useEffect(() => {
    if (editing) {
      //@ts-ignore
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} est requis.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          color: isEmpty(record[dataIndex] ?? "") ? "brown" : undefined,
          cursor: !haveRightRoles(["ROLE_DEV"], roles)
            ? "not-allowed"
            : undefined,
        }}
        onClick={haveRightRoles(["ROLE_DEV"], roles) ? toggleEdit : undefined}
      >
        {isEmpty(record[dataIndex] ?? "")
          ? `ID absent: veuillez ajouter`
          : children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

/*interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}*/

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export function OrgAgentList() {
  const agents = useSelector((state: RootState) => state.user.all);
  const [dataSource, setDataSource] = React.useState<any[]>([]);

  const onInit = React.useCallback(() => {
    const elements = agents.map((p) => {
      return {
        ...p,
        fingerprintId: `${p.personals?.fingerprintId ?? ""}`,
      };
    });
    setDataSource(elements);
  }, [agents]);

  React.useEffect(() => {
    onInit();
  }, [agents]);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex?: string;
  })[] = [
    {
      title: "ID d'empreinte digitale",
      dataIndex: "fingerprintId",
      width: "30%",
      editable: true,
    },
    {
      title: "Nom de l'agent",
      //dataIndex:"users",
      render(value, record, index) {
        return userHelper.getUsername(value);
      },
    },
    /*{
      title: "operation",
      dataIndex: "operation",
      //@ts-ignore
      render: (_, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },*/
  ];

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.uid === item.uid);
    const item = newData[index];
    publish(row?.uid, row?.fingerprintId);
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  async function publish(uid: string, fingerprintId: string) {
    if (!(toNumber(fingerprintId) > 0)) {
      return;
    }
    const data: any = { personals: { fingerprintId } };
    await userService
      .update(uid, data)
      .then(() => {})
      .catch((reason) => {
        const error = personalService.getError(reason);
        console.log("Reason", error);
      });
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <section>
      <Card style={{ marginTop: 10 }}>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          pagination={{ pageSize: 10 }}
        />
      </Card>
    </section>
  );
}
