import React from "react";
import Chart from "react-apexcharts";
import { Card } from "antd";
import dateHelpers from "../../utils/helper/dateHelpers";
import { toUpper, upperFirst } from "lodash";

export function AgentAttendanceChart(props: { attendances: any[] }) {
  const [data, setData] = React.useState<any[]>([]);
  const [labels, setLabels] = React.useState<any[]>([]);

  const onInit = React.useCallback(() => {
    const elementLabel: any[] = [];
    const elements: any[] = [];
    const year = new Date().getFullYear();
    for (let index = 1; index <= 12; index++) {
      const month = index < 10 ? `0${index}` : `${index}`;
      const dt = `${year}-${month}-01`;
      const date = dateHelpers.getDate(dt, "YYYY-MM-DD");
      elementLabel.push(upperFirst(dateHelpers.getDate(date, "MMM")));
      const items = props.attendances.filter((p) =>
        dateHelpers.isSameMoment(p.recordedAt, date, "month"),
      );
      elements.push(items.length);
    }
    setData(elements);
    setLabels(elementLabel);
  }, [props.attendances]);

  React.useEffect(() => {
    onInit();
  }, [props.attendances]);

  return (
    <Card style={{ height: "100%", marginTop: 10 }}>
      <Chart
        options={{
          chart: {
            id: "attendance",
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            categories: labels,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Liste de présence",
            align: "left",
          },
          subtitle: {
            text: "Mouvement au cours des mois",
            align: "left",
          },
          yaxis: {
            opposite: true,
          },
          legend: {
            horizontalAlign: "left",
          },
        }}
        series={[
          {
            name: "Nombre de présences",
            data: data,
          },
        ]}
        type="area"
        width="100%"
        height={"500px"}
      />
    </Card>
  );
}
