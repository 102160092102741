import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface gradeData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: gradeData = {
  current: {},
  list: [],
  all: [],
};

export const gradeSlice = createSlice({
  name: "grade",
  initialState,
  reducers: {
    setGrade: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setGrades: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllGrades: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllGrades, setGrade, setGrades } = gradeSlice.actions;
export default gradeSlice.reducer;
