import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface leaveData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: leaveData = {
  current: {},
  list: [],
  all: [],
};

export const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    setCurrentLeave: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setLeaves: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllLeaves: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllLeaves, setCurrentLeave, setLeaves } = leaveSlice.actions;
export default leaveSlice.reducer;
