import { Button, Table, Typography } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import payslipService from "../services/payslipService";
import { Loader2 } from "../components/loader2";
import dateHelpers from "../utils/helper/dateHelpers";
import userHelper from "../utils/helper/userHelper";

export function MyPayslipScreen() {
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const params = useParams();

  const onInit = React.useCallback(async () => {
    setLoading(true);
    const user = userHelper.getProfile();
    await payslipService
      .getByKey(
        `personal=${user?.personals?.uid}&order[year]=asc&order[month]=asc`,
      )
      .then((response) => {
        const data = payslipService.getData(response);
        setItems(data);
      })
      .catch(() => {});
    setLoading(false);
  }, [params]);

  React.useEffect(() => {
    onInit();
  }, [params]);

  return (
    <>
      <main>
        <Typography.Title style={{ textAlign: "center" }}>
          {`Mes feuilles de paie`}
        </Typography.Title>
        <Table
          dataSource={items}
          columns={[
            {
              title: "Période de paiement",
              render(value, record, index) {
                const m = value.month;
                const y = value.year;
                let date = "";
                if (m > 0 && y > 0) {
                  date = dateHelpers.getDate(`${y}-${m}-01`, "MMMM YYYY");
                }
                return date;
              },
            },
            {
              title: "Net payé",
              dataIndex: "total",
              render(value, record, index) {
                return `${value} $`;
              },
            },
            {
              title: "Date de création de la fiche",
              dataIndex: "createdAt",
              render(value, record, index) {
                return dateHelpers.getDate(value, "dddd DD MMMM YYYY HH:mm");
              },
            },
            {
              title: "Dernière modification",
              dataIndex: "updatedAt",
              render(value, record, index) {
                return dateHelpers.getDate(value, "dddd DD MMMM YYYY HH:mm");
              },
            },
            {
              render(value, record, index) {
                return (
                  <Button href={`/cards/${value.uid}/view`}>
                    {`Afficher`}
                  </Button>
                );
              },
            },
          ]}
        />
      </main>
      <Loader2 loading={loading} />
    </>
  );
}
