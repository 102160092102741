import { isEmpty, toUpper, upperFirst } from "lodash";
import {
  setAllUsers,
  setUserLeaves,
  setUserProfile,
  setUserRoles,
} from "../../redux/userReducer";
import userService from "../../services/userService";
import orgHelper from "./orgHelper";
import leaveService from "../../services/leaveService";

class UserHelper {
  private key = "rh_user";
  private keyRoles = "rh_user_roles";

  async setCurrentProfile(user: any, dispatch: any) {
    localStorage.setItem(this.key, JSON.stringify(user));
    dispatch(setUserProfile(user));
  }
  async setRoles(roles: any, dispatch: any) {
    localStorage.setItem(this.keyRoles, JSON.stringify(roles));
    dispatch(setUserRoles(roles));
  }

  logout() {
    localStorage.removeItem(this.key);
    localStorage.removeItem(this.keyRoles);
  }

  getProfile() {
    let result: any = null;
    const v = localStorage.getItem(this.key);
    if (!isEmpty(v)) {
      result = JSON.parse(v ?? "");
    }
    return result;
  }

  getRoles() {
    let result: any = null;
    const v = localStorage.getItem(this.keyRoles);
    if (!isEmpty(v)) {
      result = JSON.parse(v ?? "");
    }
    return result;
  }

  async getAllUsers(dispatch?: any) {
    let list: any = [];
    await userService
      .getByKey(
        `exists[personals]=true&order[lastName]=asc&order[firstName]=asc`,
      )
      .then((response) => {
        list = userService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllUsers(list));
        }
      })
      .catch(() => {});
    return list;
  }

  async getAllNoAgents(dispatch?: any) {
    let list: any = [];
    await userService
      .getByKey(`exists[personals]=false`)
      .then((response) => {
        list = userService.getData(response);
        if (typeof dispatch === "function") {
          //dispatch(setAllUsers(list));
        }
      })
      .catch(() => {});
    return list;
  }

  async initData(dispatch: any) {
    await orgHelper.getAllDepartments(dispatch);
    this.getLeaves(dispatch);
    await orgHelper.getAllLeaves(dispatch);
    await orgHelper.getAllGrades(dispatch);
    await this.getAllUsers(dispatch);
    await orgHelper.getAllAttendances(dispatch);
  }

  async getLeaves(dispatch: any) {
    const user = this.getProfile();
    let list: any = [];
    await leaveService
      .getByKey(`personal.users=${user?.uid}`)
      .then((response) => {
        list = leaveService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setUserLeaves(list));
        }
      })
      .catch(() => {});
    return list;
  }

  getUsername(user: any) {
    return `${toUpper(user?.lastName)} ${toUpper(user?.middleName)} ${upperFirst(user?.firstName)}`;
  }
  isUser(user: any) {
    return !isEmpty(user?.auth);
  }
}

export default new UserHelper();
