import { Avatar, Button, Card, Col, Row, Typography } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { isEmpty } from "lodash";
import React, { PropsWithChildren } from "react";

export const CardPerson: React.FC<
  PropsWithChildren<{
    title: string;
    subTitle?: string;
    photo?: string;
    actions?: {
      onPress?: (e?: any) => void;
      title?: string;
      label?: string;
      shape?: "default" | "circle" | "round";
      mode?: "link" | "text" | "default" | "primary" | "dashed";
    }[];
  }>
> = ({ title, actions, children, photo, subTitle }) => {
  return (
    <Card>
      <Row gutter={8} align="middle">
        <Col>
          <Avatar size={"large"} />
        </Col>
        <Col flex={1}>
          <div>
            <Typography.Text strong>{title}</Typography.Text>
          </div>
          {!isEmpty(subTitle) && (
            <div>
              <Typography.Text>{subTitle}</Typography.Text>
            </div>
          )}
        </Col>
        <Col>
          {(actions?.length ?? 0) > 0 && (
            <Row gutter={4} align="middle">
              {actions?.map((p) => (
                <Col>
                  <Button
                    onClick={p.onPress}
                    title={p.title}
                    shape={p.shape}
                    type={p.mode}
                  >
                    {p.label}
                  </Button>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};
