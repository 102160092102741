import { Col, Row, Typography } from "antd";
import React from "react";
import { colorPrimary } from "../../utils/theme/color";
import { OrgUploadPresences } from "./uploadPresence";
import { OrgAgentList } from "./agentPresence";
import orgHelper from "../../utils/helper/orgHelper";
import { useDispatch } from "react-redux";

const { Text, Title, Paragraph } = Typography;

export function OrganizationShowView() {
  const dispatch = useDispatch();
  const onInit = React.useCallback(() => {
    orgHelper.getAllAttendances(dispatch);
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);
  return (
    <>
      <OrgUploadPresences />
      <OrgAgentList />
    </>
  );
}
