import { Button, Card, Typography, Upload, notification } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import React from "react";
import readXlsxFile from "read-excel-file";
import { getBase64 } from "../../utils/helper/title";
import { isEmpty } from "lodash";
import attendanceService from "../../services/attendanceService";
import orgHelper from "../../utils/helper/orgHelper";
import { useDispatch } from "react-redux";

export function OrgUploadPresences() {
  const [fileToUpload, setFileToUpload] = React.useState<any | null>(null);
  const [list, setList] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    const file = info.file;
    setFileToUpload(file.originFileObj);
    setList([info.file]);
  }

  async function onPublish(url: any) {
    if (!isEmpty(fileToUpload)) {
      const data = new FormData();
      data.append("file", fileToUpload);
      setLoading(true);
      await attendanceService
        .store(data)
        .then(async () => {
          await orgHelper.getAllAttendances(dispatch);
          setFileToUpload(null);
          setLoading(false);
          setList([]);
        })
        .catch((reason) => {
          const error = attendanceService.getError(reason);
          notification.error({ message: "Erreur", description: error });
        });
      setLoading(false);
    }
  }

  return (
    <section>
      <Card>
        <div style={{ textAlign: "center" }}>
          <Upload
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            multiple={false}
            fileList={list}
            onChange={handleChange}
          >
            <Button>{`Ajouter la liste de présence`}</Button>
          </Upload>
          <Typography.Paragraph
            style={{ maxWidth: 500, margin: "auto", padding: 10 }}
          >
            {`Comme administrateur, vous pouvez exporter des fichiers de présence en format excel pour l'enregistrement`}
          </Typography.Paragraph>
          {!isEmpty(fileToUpload) && (
            <div className="anim-attendance" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                loading={loading}
                onClick={onPublish}
                disabled={loading}
              >
                {`Enregistrer`}
              </Button>
            </div>
          )}
        </div>
      </Card>
    </section>
  );
}
