import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import { lightTheme } from "./utils/theme/light";
import "./assets/styles/app.scss";
import { AdminLayout } from "./layout/admin";
import { HomeLayout } from "./layout/home";
import { Page404Screen } from "./layout/404Page";

function App() {
  return (
    <ConfigProvider theme={lightTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/home/*" element={<HomeLayout />} />
          <Route path="/*" element={<AdminLayout />} />
          <Route path="*" element={<Page404Screen />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
