import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface userData {
  current: any;
  list: any[];
  filters: any[];
  all: any[];
}

export interface subjectInterface {
  "@context"?: string;
  "@id"?: string;
  "@type"?: string;
  title: string;
  category?: string;
  level: string;
  lessons: any[];
  state: string;
}

const initialState: userData = {
  current: {},
  list: [],
  all: [],
  filters: [],
};

export const subjectSlice = createSlice({
  name: "personal",
  initialState,
  reducers: {
    setPersonal: (state, action: PayloadAction<subjectInterface>) => {
      state.current = action.payload;
    },
    setPersonals: (state, action: PayloadAction<subjectInterface[]>) => {
      state.list = action.payload;
    },
    setAllPersonals: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllPersonals, setPersonal, setPersonals } =
  subjectSlice.actions;
export default subjectSlice.reducer;
