import React from "react";
import { DashboardMenu } from "./dashboard/menus";
import { DashboardStatics } from "./dashboard/statics";

export function DashboardScreen() {
  return (
    <>
      <main>
        <DashboardMenu />
        <DashboardStatics />
        {/**
         * 1. congés en cours (name, fonction, date de fin de congé)
         * 2. congés imminnents (à vernir dans mois d'un mois): name, fonction, date de début, date de fin
         * 3. vos fiches
         */}
      </main>
    </>
  );
}
