import React from "react";
import { CreateCalendar } from "./calendar/create";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import dateHelpers from "../utils/helper/dateHelpers";
import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
  notification,
} from "antd";
import userHelper from "../utils/helper/userHelper";
import { getLeaveStatus } from "../utils/helper/title";
import { LiteralUnion } from "antd/es/_util/type";
import { PresetColorType } from "antd/es/_util/colors";
import { Icon } from "@iconify/react";
import { CalendarView } from "./calendar/view";
import leaveService from "../services/leaveService";
import orgHelper from "../utils/helper/orgHelper";

export function CalendarScreen() {
  const leaves = useSelector((state: RootState) => state.leave.all);
  const [current, setCurrent] = React.useState<any>({});
  const [open, setOpen] = React.useState(false);

  function handleOpen(value: any) {
    setCurrent(value);
    setOpen(true);
  }
  return (
    <>
      <main>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CreateCalendar />
        </div>
        <div>
          <Table
            dataSource={leaves}
            columns={[
              {
                title: "Nom de l'agent",
                dataIndex: "personal",
                render(value, record, index) {
                  return userHelper.getUsername(value?.users ?? {});
                },
              },
              {
                title: "Période de vacances",
                render(value, record, index) {
                  let result = ``;
                  const startAt = dateHelpers.getDate(
                    value.startAt,
                    "dddd DD MMM YYYY",
                  );
                  const finishAt = dateHelpers.getDate(
                    value.finishAt,
                    "dddd DD MMM YYYY",
                  );
                  result = `${startAt} - ${finishAt}`;
                  return result;
                },
              },
              {
                title: "Temps",
                render(value, record, index) {
                  const diff =
                    dateHelpers.getDifference(
                      value.finishAt,
                      value.startAt,
                      "day",
                    ) + 1;
                  let result = `${diff} jour${diff > 1 ? "s" : ""}`;
                  if (diff === 0) {
                    result = "Durée incertaine";
                  }
                  return result;
                },
              },
              {
                title: "Catégorie",
                dataIndex: "category",
              },
              {
                title: "Description",
                dataIndex: "description",
                render(value, record, index) {
                  return (
                    <Typography.Paragraph ellipsis={{ rows: 1 }}>
                      {value}
                    </Typography.Paragraph>
                  );
                },
              },
              {
                title: "Etat de la demande",
                render(value, record, index) {
                  const { badge, result } = getLeaveStatus(value);
                  let color: LiteralUnion<
                    | PresetColorType
                    | "success"
                    | "processing"
                    | "error"
                    | "default"
                    | "warning"
                  > = "default";
                  switch (badge) {
                    case "in_pending":
                      color = "warning";
                      break;
                    case "in_progress":
                      color = "success";
                      break;
                    case "canceled":
                      color = "error";
                      break;
                    case "rejected":
                      color = "red-inverse";
                      break;
                  }
                  return <Tag color={color}>{result}</Tag>;
                },
              },
              {
                render(value, record, index) {
                  return (
                    <div>
                      <Button
                        onClick={() => handleOpen(value)}
                        shape="circle"
                        type="primary"
                      >
                        <Icon icon="ph:eye-bold" />
                      </Button>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </main>
      <CalendarView current={current} open={open} setOpen={setOpen} />
    </>
  );
}
