import { ArrowUpOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Col, Divider, List, Row, Statistic } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Icon } from "@iconify/react";
import { DashboardImminent } from "./imminents";

export function DashboardStatics() {
  const [data, setData] = React.useState<any[]>([]);
  const agents = useSelector((state: RootState) => state.user.all);

  const onInit = React.useCallback(() => {
    const elements: any[] = [
      {
        label: "Agents",
        value: agents.length,
        prefix: <UserOutlined />,
      },
      {
        label: "Agents en congé",
        value: 0,
        prefix: <Icon icon="twemoji:calendar" />,
      },
      {
        label: "Congés imminents",
        value: 0,
        prefix: <ArrowUpOutlined />,
      },
    ];
    setData(elements);
  }, [agents]);

  React.useEffect(() => {
    onInit();
  }, [agents]);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Card bordered={false}>
          <Statistic
            title={item.label ?? ""}
            value={item?.value}
            precision={0}
            valueStyle={{ color: "#3f8600" }}
            prefix={item.prefix}
            suffix=""
          />
        </Card>
      </List.Item>
    );
  };
  return (
    <section>
      <Divider />
      <List
        className="list__menu"
        grid={{ xs: 1, xl: 3, md: 2, sm: 2, lg: 3, xxl: 3, gutter: 24 }}
        renderItem={renderItem}
        dataSource={data}
      />
      <Row gutter={24}>
        <Col flex={1}>
          <DashboardImminent />
        </Col>
        <Col flex={1}>
          <DashboardImminent />
        </Col>
      </Row>
    </section>
  );
}
