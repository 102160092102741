import { Table, Tag } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helper/userHelper";
import dateHelpers from "../../utils/helper/dateHelpers";
import { OrgAttendanceFilter } from "./filterAttendance";

export function OrgAttendances() {
  const data = useSelector((state: RootState) => state.attendance.all);
  const [loading, setLoading] = React.useState(false);
  return (
    <section>
      <OrgAttendanceFilter setLoading={setLoading} />
      <Table
        dataSource={data}
        className="table__1"
        loading={loading}
        columns={[
          {
            title: "Nom de l'agent",
            dataIndex: "agent",
            render(value, record, index) {
              return userHelper.getUsername(value?.users);
            },
          },
          {
            dataIndex: "recordedAt",
            title: "Date d'enregistrement",
            render(value, record, index) {
              return dateHelpers
                .getDate(value, "dddd DD MMM YYYY")
                ?.toUpperCase();
            },
          },
          {
            title: "Les enregistrement",
            dataIndex: "times",
            render(value, record, index) {
              return (
                <div>
                  {value?.map((p: any) => {
                    return <Tag color="blue">{p}</Tag>;
                  })}
                </div>
              );
            },
          },
        ]}
        pagination={{ pageSize: 10 }}
      />
    </section>
  );
}
