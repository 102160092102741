import { Button, Drawer, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../../components/input";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import orgHelper from "../../utils/helper/orgHelper";
import gradeService from "../../services/gradeService";
import { toNumber } from "lodash";
import { haveRightRoles } from "../../utils/helper/title";
import { RootState } from "../../redux/store";

export function CreateGrade(props: {
  edit?: boolean;
  item?: any;
  visible?: boolean;
  setVisible?: (v: boolean) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const roles = useSelector((state: RootState) => state.user.roles);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      salary: "0",
    },
    onSubmit,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
      salary: yup.number().typeError("Une valeur numérique uniquement"),
    }),
  });

  async function onSubmit() {
    const { name, salary } = formik.values;
    let amount = toNumber(salary) ?? 0;
    const data: any = { name, status: "active", salary: amount };
    setLoading(true);
    if (props.edit) {
      await onUpdate(data);
    } else {
      await onSave(data);
    }
    setLoading(false);
  }

  async function onSave(data: any) {
    await gradeService
      .store(data)
      .then(async () => {
        await orgHelper.getAllGrades(dispatch);
        setOpen(false);
        if (typeof props.setVisible === "function") {
          props.setVisible(false);
        }
        formik.resetForm();
      })
      .catch((reason) => {
        const error = gradeService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await gradeService
      .update(props.item?.uid, data)
      .then(async () => {
        await orgHelper.getAllGrades(dispatch);
        setOpen(false);
        if (typeof props.setVisible === "function") {
          props.setVisible(false);
        }
        formik.resetForm();
      })
      .catch((reason) => {
        const error = gradeService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    if (typeof props.setVisible === "function") {
      props.setVisible(false);
    }
  }

  const onInitEdit = React.useCallback(() => {
    if (props.edit) {
      formik.setValues({
        ...formik.values,
        name: props.item?.name ?? "",
        salary: `${props.item?.salary ?? 0}`,
      });
    }
  }, [props.item]);

  React.useEffect(() => {
    onInitEdit();
  }, [props.item]);

  return (
    <>
      {!props.edit && haveRightRoles(["ROLE_ADMIN", "ROLE_DEV"], roles) && (
        <Button type="primary" onClick={handleOpen} style={{ margin: 16 }}>
          {`Ajouter une fonction`}
        </Button>
      )}
      <Modal
        okText={props.edit ? "Modifier" : "Enregistrer"}
        cancelText="Annuler"
        title={props.edit ? "Modifier la fonction" : "Nouvelle fonction"}
        onOk={handleSubmit}
        centered
        confirmLoading={loading}
        open={open || props.visible}
        onCancel={handleClose}
      >
        <div>
          <DillInput formik={formik} name="name" label="Titre de la fonction" />
          <DillInput
            formik={formik}
            name="salary"
            type="num"
            label="Salaire associé à la fonction"
          />
        </div>
      </Modal>
    </>
  );
}
