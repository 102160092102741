import { Button, Drawer, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput } from "../../components/input";
import * as yup from "yup";
import departmentService from "../../services/departmentService";
import { useDispatch, useSelector } from "react-redux";
import orgHelper from "../../utils/helper/orgHelper";
import { RootState } from "../../redux/store";
import { haveRightRoles } from "../../utils/helper/title";

export function CreateDepartment(props: {
  edit?: boolean;
  item?: any;
  visible?: boolean;
  setVisible?: (v: boolean) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [haveRight, setHaveRight] = React.useState(false);
  const roles = useSelector((state: RootState) => state.user.roles);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit,
    validationSchema: yup.object({
      name: yup.string().required("Champ requis"),
    }),
  });

  async function onSubmit() {
    const { name } = formik.values;
    const data: any = { name, status: "active" };
    setLoading(true);
    if (props.edit) {
      await onUpdate(data);
    } else {
      await onSave(data);
    }
    setLoading(false);
  }

  async function onSave(data: any) {
    await departmentService
      .store(data)
      .then(async () => {
        await orgHelper.getAllDepartments(dispatch);
        setOpen(false);
        if (typeof props.setVisible === "function") {
          props.setVisible(false);
        }
        formik.resetForm();
      })
      .catch((reason) => {
        const error = departmentService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
  }

  async function onUpdate(data: any) {
    await departmentService
      .update(props.item?.uid, data)
      .then(async () => {
        await orgHelper.getAllDepartments(dispatch);
        setOpen(false);
        if (typeof props.setVisible === "function") {
          props.setVisible(false);
        }
        formik.resetForm();
      })
      .catch((reason) => {
        const error = departmentService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    if (typeof props.setVisible === "function") {
      props.setVisible(false);
    }
  }

  const onInitEdit = React.useCallback(() => {
    if (props.edit) {
      formik.setValues({ ...formik.values, name: props.item?.name ?? "" });
    }
    const rightRoles = ["ROLE_DEV", "ROLE_ADMIN"];
    const r = haveRightRoles(rightRoles, roles);
    setHaveRight(r);
  }, [props.item, roles]);

  React.useEffect(() => {
    onInitEdit();
  }, [props.item, roles]);

  return (
    <>
      {!props.edit && haveRight && (
        <Button onClick={handleOpen} style={{ margin: 16 }}>
          {`Ajouter un département`}
        </Button>
      )}
      <Modal
        okText={props.edit ? "Modifier" : "Enregistrer"}
        cancelText="Annuler"
        title={props.edit ? "Modifier le département" : "Nouveau département"}
        onOk={handleSubmit}
        centered
        confirmLoading={loading}
        open={open || props.visible}
        onCancel={handleClose}
      >
        <div>
          <DillInput formik={formik} name="name" label="Nom de département" />
        </div>
      </Modal>
    </>
  );
}
