import { Button, DatePicker, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../redux/store";
import { isEmpty } from "lodash";
import { DillSelect, DillTextarea } from "../../components/input";
import { notify, typesOfVacations } from "../../utils/helper/title";
import leaveService from "../../services/leaveService";
import userHelper from "../../utils/helper/userHelper";
import type { Dayjs } from "dayjs";
import orgHelper from "../../utils/helper/orgHelper";

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export function CreateCalendar(props: {
  agent?: any;
  target?: "agent" | "profile";
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profile);
  const [value, setValue] = React.useState<RangeValue>(null);
  const dispatch = useDispatch();
  const [list, setList] = React.useState<any[]>([]);
  const formik = useFormik({
    initialValues: {
      status: "in_pending",
      startAt: "",
      finishAt: "",
      category: "",
      description: "",
      personal: ``,
    },
    onSubmit,
    validationSchema: yup.object({
      startAt: yup.string().required("Champ requis"),
      finishAt: yup.string().required("Champ requis"),
      category: yup.string().required("Champ requis"),
      personal: yup.string().required("Champ requis"),
    }),
  });
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  async function onSubmit() {
    const { category, description, finishAt, personal, startAt, status } =
      formik.values;
    const data: any = {
      description,
      category,
      finishAt,
      personal,
      startAt,
      status,
    };
    setLoading(true);
    await leaveService
      .store(data)
      .then(async () => {
        orgHelper.getAllLeaves(dispatch);
        userHelper.getLeaves(dispatch);
        notify(
          "Votre demande de congé est en attente de validation. Un administrateur sera avisé pour la traiter.",
        );
        setOpen(false);
        formik.resetForm();
        setValue(null);
      })
      .catch((reason) => {
        const error = leaveService.getError(reason);
        notification.error({ message: "Erreur détectée", description: error });
      });
    setLoading(false);
  }
  function handleSubmit() {
    formik.handleSubmit();
    const { startAt, finishAt } = formik.values;
    if (isEmpty(startAt) || isEmpty(finishAt)) {
      notification.error({ message: "Les dates du congé non définies" });
    }
  }

  const onChangeValue = React.useCallback(() => {
    const d1 = value?.[0]?.format("YYYY-MM-DD");
    const d2 = value?.[1]?.format("YYYY-MM-DD");
    formik.setFieldValue("startAt", d1 ?? "");
    formik.setFieldValue("finishAt", d2 ?? "");
  }, [value]);

  React.useEffect(() => {
    onChangeValue();
  }, [value]);

  const onInit = React.useCallback(() => {
    if (!isEmpty(props.agent)) {
      formik.setFieldValue("personal", `/api/personals/${props?.agent?.uid}`);
    } else if (!isEmpty(user?.personals?.uid)) {
      formik.setFieldValue(
        "personal",
        `/api/personals/${user?.personals?.uid}`,
      );
    }
    const elements: any[] = [];
    typesOfVacations.forEach((p) => {
      elements.push({ value: p, title: p });
    });
    elements.unshift({ value: "", title: "Sélectionner une catégorie" });
    setList(elements);
  }, [user, props.agent]);

  React.useEffect(() => {
    onInit();
  }, [user, props.agent]);

  const disabledBtn = () => {
    let result = false;
    if (props.target === "agent") {
      if (isEmpty(props.agent)) {
        result = true;
      } else {
        //verify rights
        result = false;
      }
    } else {
      if (isEmpty(user?.personals?.uid)) {
        result = true;
      }
    }
    return result;
  };

  return (
    <>
      <Button
        disabled={disabledBtn()}
        onClick={handleOpen}
      >{`Demander un congé`}</Button>
      <Modal
        onOk={handleSubmit}
        okText="Enregistrer"
        cancelText="Annuler"
        onCancel={handleClose}
        title={
          props.target !== "agent"
            ? `Demande de congé`
            : `Demande de congé pour ${userHelper.getUsername(props.agent?.users)}`
        }
        centered
        open={open}
        confirmLoading={loading}
      >
        <div>
          <RangePicker value={value} onChange={setValue} />
          <DillSelect
            list={list}
            name="category"
            formik={formik}
            label="Catégorie de vacances"
          />
          <DillTextarea
            formik={formik}
            name="description"
            label="Note associée à la demande de congé"
          />
        </div>
      </Modal>
    </>
  );
}
