import { Modal, Spin } from "antd";
import React from "react";

export function LoaderComponent(props: { loading: boolean }) {
  return (
    <>
      <Modal
        className="loader--modal"
        cancelText={<div>jjk</div>}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        footer={<div></div>}
        centered
        open={props.loading}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      </Modal>
    </>
  );
}
