import { Col, Row } from "antd";
import React from "react";
import {
  DillInput,
  DillRadio,
  DillSelect,
  DillTextarea,
} from "../../components/input";

export function CreateAgentIdentity(props: { formik: any; edit: boolean }) {
  return (
    <div>
      <Row gutter={32}>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="lastName" label="Nom" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="middleName" label="Post-nom" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="firstName" label="Prénom" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="email" label="E-mail" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput formik={props.formik} name="phone" label="Téléphone" />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillRadio
            formik={props.formik}
            list={[
              { value: "f", title: "Femme" },
              { value: "m", title: "Homme" },
            ]}
            type="button"
            name="gender"
            label="Genre"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillSelect
            list={[
              {
                value: "",
                title: "Séléctionner une situation",
              },
              { value: "Marié(e)", title: "Marié(e)" },
              { value: "Veuf(ve)", title: "Veuf(ve)" },
              { value: "Célibataire", title: "Célibataire" },
              { value: "Divorcé(e)", title: "Divorcé(e)" },
            ]}
            formik={props.formik}
            name="maritalStatus"
            label="Situation matrimoniale"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput
            formik={props.formik}
            name="children"
            type="num"
            label="Nombre d'enfants"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillInput
            formik={props.formik}
            name="birthDate"
            label="Date de naissance"
            type="date"
          />
        </Col>
        <Col lg={12} xs={24} md={12}>
          <DillTextarea formik={props.formik} name="address" label="Adresse" />
        </Col>
      </Row>
    </div>
  );
}
