import { setAllAttendances } from "../../redux/attendanceReducer";
import { setAllDepartments } from "../../redux/departmentReducer";
import { setAllGrades } from "../../redux/gradeReducer";
import { setAllLeaves } from "../../redux/leaveReducer";
import attendanceService from "../../services/attendanceService";
import departmentService from "../../services/departmentService";
import gradeService from "../../services/gradeService";
import leaveService from "../../services/leaveService";

class OrgHelper {
  async getAllDepartments(dispatch?: any) {
    let list: any = [];
    await departmentService
      .getByKey(``)
      .then((response) => {
        list = departmentService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllDepartments(list));
        }
      })
      .catch(() => {});
    return list;
  }
  async getAllLeaves(dispatch?: any) {
    let list: any = [];
    await leaveService
      .getByKey(``)
      .then((response) => {
        list = leaveService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllLeaves(list));
        }
      })
      .catch(() => {});
    return list;
  }

  async getAllGrades(dispatch?: any) {
    let list: any = [];
    await gradeService
      .getByKey(``)
      .then((response) => {
        list = gradeService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllGrades(list));
        }
      })
      .catch(() => {});
    return list;
  }

  async getAllAttendances(dispatch?: any, query?: string) {
    let list: any = [];
    await attendanceService
      .getByKey(`order[recordedAt]=asc&${query ?? ""}`)
      .then((response) => {
        list = attendanceService.getData(response);
        if (typeof dispatch === "function") {
          dispatch(setAllAttendances(list));
        }
      })
      .catch(() => {});
    return list;
  }
}

export default new OrgHelper();
