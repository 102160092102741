import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface levelData {
  current: any;
  list: any[];
  all: any[];
  filter: any;
}

const initialState: levelData = {
  current: {},
  list: [],
  all: [],
  filter: {
    dateRange: null,
    lastName: "",
    firstName: "",
    agent: "",
  },
};

export const levelSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    setAttendance: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setAttendances: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllAttendances: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setFilterAttendance: (state, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
  },
});
export const {
  setAllAttendances,
  setAttendance,
  setAttendances,
  setFilterAttendance,
} = levelSlice.actions;
export default levelSlice.reducer;
