import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  List,
  Row,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import userHelper from "../../utils/helper/userHelper";
import { LoaderComponent } from "../../components/loader";
import personalService from "../../services/personalService";
import { setPersonal } from "../../redux/personalReducer";
import payslipService from "../../services/payslipService";
import { setPayslips } from "../../redux/payslipsReducer";
import dateHelpers from "../../utils/helper/dateHelpers";
import { getFileURL } from "../../utils/helper/title";
import { AgentCalendar } from "./calendar";
import leaveService from "../../services/leaveService";
import { Icon } from "@iconify/react";
import { AgentAttendanceChart } from "./attendanceChart";
import attendanceService from "../../services/attendanceService";

export function AgentViewScreen() {
  const params = useParams();
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const agent = useSelector((state: RootState) => state.personal.current);
  const payslips = useSelector((state: RootState) => state.payslip.list);
  const [currentAgent, setCurrentAgent] = React.useState<any>({});
  const [data, setData] = React.useState<any[]>([]);
  const [attendances, setAttendances] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [leaves, setLeaves] = React.useState<any[]>([]);

  function handleCreatePaylist() {
    navigate(`/payslips/${agent.uid}/?year=${year}&month=${month}`);
  }
  function handleOpenCard(item: any) {
    navigate(`/cards/${item.uid}/view`);
  }

  function getProfile() {
    const user = agent?.users ?? {};
    const elements: any[] = [
      {
        title: "Département",
        value: agent?.department?.name ?? "-------",
        icon: (
          <Icon className="icon" icon="healthicons:outpatient-department" />
        ),
      },
      {
        icon:
          user?.gender === "m" ? (
            <Icon icon="el:user" className="icon" />
          ) : (
            <Icon className="icon" icon="vaadin:user" />
          ),
        title: "Genre",
        value:
          user?.gender === "m"
            ? "Homme"
            : user?.gender === "f"
              ? "Femme"
              : "non défini",
      },
      {
        title: "Téléphone",
        value: user?.phone ?? "-------",
        icon: <Icon className="icon" icon="ic:twotone-phone" />,
      },
      {
        title: "E-mail",
        value: user?.email ?? "-------",
        icon: <Icon className="icon" icon="fontisto:email" />,
      },
      {
        title: "Adresse",
        value: user?.address ?? "-------",
        icon: <Icon className="icon" icon="entypo:address" />,
      },
      {
        title: "Age",
        value: dateHelpers.getAge(user.birthDate),
        icon: <Icon className="icon" icon="game-icons:ages" />,
      },
      {
        title: "Situation matrimoniale",
        value: agent?.maritalStatus ?? "-------",
        icon: <Icon className="icon" icon="mdi:ring" />,
      },
      {
        title: "Salaire de base",
        value: agent?.positionHeld?.salary ?? agent?.baseSalary ?? "-------",
        icon: <Icon className="icon" icon="emojione:money-bag" />,
      },
    ];
    if (!isEmpty(agent?.supervisor)) {
      elements.push({
        title: "Superviseur",
        value: userHelper.getUsername(agent?.supervisor?.users),
        icon: <Icon className="icon" icon="mingcute:directory-line" />,
      });
    }
    setData(elements);
  }

  const onInit = React.useCallback(async () => {
    const uid = params.id;
    if (isEmpty(uid)) {
      return;
    }
    setLoading(true);
    await personalService
      .get(uid + "")
      .then(async (response) => {
        const item = { ...response.data };
        item.baseSalary = item?.positionHeld?.salary ?? item?.baseSalary ?? 0;
        dispatch(setPersonal(item));
        setCurrentAgent(item);
        await getPayslips(uid + "");
      })
      .catch(() => {});
    await getLeaves();
    await getAttendances();
    setLoading(false);
  }, [params]);

  async function getLeaves() {
    const uid = params.id;
    await leaveService
      .getByKey(`personal=${uid}`)
      .then((response) => {
        const data = leaveService.getData(response);
        setLeaves(data);
      })
      .catch(() => {});
  }

  async function getAttendances() {
    const uid = params.id;
    await attendanceService
      .getByKey(`agent=${uid}`)
      .then((response) => {
        const data = attendanceService.getData(response);
        setAttendances(data);
      })
      .catch(() => {});
  }

  async function getPayslips(uid: string) {
    let result: any[] = [];
    await payslipService
      .getByKey(`personal=${uid}&order[year]=asc`)
      .then((response) => {
        result = payslipService.getData(response);
        dispatch(setPayslips(result));
      })
      .catch((reason) => {});
    return result;
  }

  React.useEffect(() => {
    onInit();
  }, [params]);

  const getData = React.useCallback(() => {
    getProfile();
  }, [agent]);

  React.useEffect(() => {
    getData();
  }, [agent]);

  const renderItem = (item: any) => {
    return (
      <List.Item>
        <Row gutter={16} align="middle">
          {!isEmpty(item.icon) && <Col>{item.icon}</Col>}
          <Col flex={1}>
            <div>
              <Typography.Text
                style={{ display: "block", textAlign: "left" }}
                strong
              >
                {item.title}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                style={{ textAlign: "left", display: "block", fontSize: 16 }}
              >
                {item.value}
              </Typography.Text>
            </div>
          </Col>
        </Row>
      </List.Item>
    );
  };

  const renderPay = (item: any) => {
    return (
      <List.Item>
        <Row wrap={false} style={{ width: "100%" }} gutter={8} align="middle">
          <Col flex={1}>
            <Typography.Text strong>
              {dateHelpers
                .getDate(`${item.year}-${item.month}-01`, "MMMM YYYY")
                ?.toUpperCase()}
            </Typography.Text>
            <Typography.Text style={{ fontWeight: "lighter" }}>
              {item.description}
            </Typography.Text>
          </Col>
          <Col>
            <Button
              onClick={() => handleOpenCard(item)}
              type="link"
            >{`Afficher`}</Button>
          </Col>
        </Row>
      </List.Item>
    );
  };

  return (
    <>
      <main>
        <Row gutter={32} align="stretch">
          <Col lg={8} xs={24}>
            <Card style={{ height: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  size={80}
                  src={getFileURL(agent?.users?.photo)}
                  className={`img--user ${isEmpty(agent?.users?.photo) ? "avatar_empty" : ""}`}
                >
                  {agent?.users?.gender === "m" ? (
                    <Icon
                      style={{ fontSize: 40 }}
                      icon="el:user"
                      className="icon"
                    />
                  ) : (
                    <Icon style={{ fontSize: 40 }} icon="vaadin:user" />
                  )}
                </Avatar>
                <div>
                  <Typography.Text style={{ fontWeight: "lighter" }}>
                    {agent?.positionHeld?.name ?? ""}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text strong>
                    {userHelper.getUsername(agent?.users)}
                  </Typography.Text>
                </div>
                <Divider />
                <List dataSource={data} renderItem={renderItem} />
                <Divider />
                {
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={handleCreatePaylist}
                      danger
                    >{`Créer un bulletin de paie`}</Button>
                  </div>
                }
              </div>
            </Card>
          </Col>
          <Col lg={16} xs={24}>
            <Card style={{ height: "100%" }}>
              <AgentCalendar leaves={leaves} agent={currentAgent} />
            </Card>
          </Col>
          <Col lg={8} xs={24}>
            <Card style={{ height: "100%", marginTop: 10 }}>
              <Typography.Title
                level={2}
                style={{ fontSize: 16, textAlign: "center" }}
              >
                {`Les fiches de paie complétées`}
              </Typography.Title>
              <Divider />
              <List
                dataSource={[...payslips].sort(
                  (a, b) => (a.month ?? 0) - (b.month ?? 0),
                )}
                renderItem={renderPay}
              />
            </Card>
          </Col>
          <Col lg={16} xs={24}>
            <AgentAttendanceChart attendances={attendances} />
          </Col>
        </Row>
      </main>
      <LoaderComponent loading={loading} />
    </>
  );
}
