import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getRoutePathOfComponent, routes } from "../routes";
import { TopicMenu } from "../components/topicMenu";
import { NavBar } from "../components/navbar";
import { Layout, Spin, message } from "antd";
import { SideBar } from "../components/sidebar";
import userHelper from "../utils/helper/userHelper";
import { useDispatch } from "react-redux";
import { Page404Screen } from "./404Page";
import { isEmpty } from "lodash";
import authService from "../services/authService";
import { notify } from "../utils/helper/title";
import { Loader2 } from "../components/loader2";
import { SidebarMenu, Theme } from "../components/sidebarMenu";
import { NavbarMobile } from "../components/navbarMobile";

export function AdminLayout() {
  const [contentIndex, setContentIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = React.useState("0");
  const [theme, setTheme] = React.useState<Theme>("light");
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(true);

  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRtl(e.target.checked);
  };

  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  const handleToggle = () => {
    setToggled(true);
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };
  const [topics] = React.useState<any[]>(
    [...routes]
      ?.filter((p) => p.layout === "admin" && p.isMenu)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
  );
  const changeSelectedKey = (event: any) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    const route = topics[key] ?? {};
    const link = getRoutePathOfComponent("", route.path);
    navigate(link);
  };
  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );

  const onInit = React.useCallback(async () => {
    const r = authService.verifyToken();
    if (!r) {
      notify("Votre session est expirée! Veuillez vous réconnecter");
      authService.logout();
      navigate("/home");
    }
    const profile = userHelper.getProfile();
    const roles = userHelper.getRoles();
    const token = authService.getToken();
    if (isEmpty(token) || isEmpty(profile)) {
      notify("Vous n'êtes pas authentifié");
      navigate("/home/");
    }
    userHelper.setCurrentProfile(profile, dispatch);
    userHelper.setRoles(roles, dispatch);
    await userHelper.initData(dispatch);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    onInit();
    function OnJwt() {
      const r = authService.verifyToken();
      if (!r) {
        notify("Votre session est expirée! Veuillez vous réconnecter");
        authService.logout();
        navigate("/home");
      }
    }
    const interval = setInterval(OnJwt, 5 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Loader2 loading={loading} />
      {broken && <NavbarMobile onMenu={handleToggle} />}
      <div style={{ display: "flex", height: "100%" }}>
        {/* <NavBar menu={Menu} /> */}
        <div style={{ minHeight: "100vh", position: "relative" }}>
          <SidebarMenu
            collapsed={false}
            hasImage={hasImage}
            rtl={rtl}
            setBroken={setBroken}
            setToggled={setToggled}
            theme={theme}
            toggled={toggled}
          />
        </div>
        <Layout>
          <SideBar menu={Menu} />
          <Layout.Content className="content">
            <Routes>
              {[...routes]
                .filter((p) => p.layout === "admin")
                .map((p, index) => (
                  <Route
                    path={getRoutePathOfComponent("", p.path)}
                    element={<p.component />}
                  />
                ))}
              <Route path="*" element={<Page404Screen />} />
            </Routes>
          </Layout.Content>
        </Layout>
      </div>
    </>
  );
}
