import { Button, Card, Typography, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DillInput, DillPassword } from "../components/input";
import { useNavigate } from "react-router-dom";
import userService from "../services/userService";
import authService from "../services/authService";
import userHelper from "../utils/helper/userHelper";
import { useDispatch } from "react-redux";
import { notify } from "../utils/helper/title";

export function LoginScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
    validationSchema: yup.object({
      email: yup
        .string()
        .required("Champ requis")
        .email("Veuillez vérifier votre E-mail"),
      password: yup
        .string()
        .required("Champ requis")
        .min(8, "Minimun 8 caractères"),
    }),
  });

  async function onSubmit() {
    setLoading(true);
    const { password, email } = formik.values;
    const data: any = { email, password };
    await userService
      .login(data)
      .then(async (response) => {
        const item = response.data;
        authService.setToken(item.token);
        userHelper.setCurrentProfile(item.user, dispatch);
        userHelper.setRoles(item.roles, dispatch);
        navigate("/");
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur d'authentification",
          description: error,
        });
        formik.setErrors({
          password: "Veuillez vérifier peut-être votre mot de passe",
          email: "Veuillez vérifier peut-être votre adresse E-mail",
        });
        notify("bonsoie");
        setLoading(false);
      });
  }

  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <main className="main--login">
        {/* ts-ignore */}
        <video
          //src={require("../assets/video/sound.mp4")}
          className="video"
          playsInline
          autoPlay
          loop
        ></video>
        <audio
          autoPlay
          playsInline
          //src={require("../assets/audio/sunflower-street-drumloop-85bpm-163900.mp3")}
        ></audio>
        <Card className="card login">
          {/*<Typography.Title>KBOLA</Typography.Title>*/}
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: "100%", maxWidth: "100px" }}
              src={require("../assets/images/logo-cami.png")}
              alt=""
              className="login--logo"
            />
          </div>
          <Typography.Title
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              padding: 10,
            }}
          >
            {`Connexion`}
          </Typography.Title>
          <div>
            <DillInput
              formik={formik}
              name="email"
              type="email"
              label="Adresse E-mail"
            />
            <DillPassword
              formik={formik}
              name="password"
              type="password"
              label="Mot de passe"
            />
          </div>
          <div
            className="input"
            style={{
              padding: "15px 20px 0",
              margin: "auto",
              maxWidth: 200,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
              shape="round"
              type="default"
            >{`Se connecter`}</Button>
          </div>
          <div
            style={{ paddingTop: 30, margin: "auto", maxWidth: 200 }}
            className="input"
          >
            <Button
              style={{ color: "#FFF" }}
              type="link"
            >{`Mot de passe oublié ?`}</Button>
          </div>
        </Card>
      </main>
      <div className="main--login-oreol">
        <img src={require("../assets/images/logo.png")} alt="" />
      </div>
    </>
  );
}
