import {
  Button,
  Col,
  Divider,
  Drawer,
  Modal,
  Row,
  Steps,
  Typography,
  Upload,
  UploadProps,
  message,
  notification,
} from "antd";
import React from "react";
import { useFormik } from "formik";
import { CreateAgentIdentity } from "./agentIdentity";
import { CreateAgentPrime } from "./agentPrime";
import { CreateAgentGrade } from "./agentGrade";
import * as yup from "yup";
import { isEmpty, toNumber } from "lodash";
import userService from "../../services/userService";
import userHelper from "../../utils/helper/userHelper";
import { useDispatch } from "react-redux";
import personalService from "../../services/personalService";
import { CardPerson } from "../../components/cardPerson";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getBase64, getFileURL } from "../../utils/helper/title";

export function CreateAgent(props: {
  edit?: boolean;
  setOpen?: (v: boolean) => void;
  open?: boolean;
  item?: any;
}) {
  const [step, setStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [loadImage, setLoadImage] = React.useState(false);

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoadImage(true);
      return;
    }
    console.log("INFO", info.file.percent);
    //if (info.file.status === "done") {
    // Get this url from response in real world.
    formik1.setFieldValue("photo", info?.file?.originFileObj ?? {});
    getBase64(info.file.originFileObj, (url) => {
      setLoadImage(false);
      setImageUrl(url);
    });
    //}
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <Typography.Text style={{ marginTop: 8 }}>
        Modifier la photo de profil
      </Typography.Text>
    </button>
  );

  const formik1 = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      address: "",
      gender: "f",
      birthDate: "",
      photo: {},
      phone: "",
      mobile: "",
      email: "",
      children: "0",
      maritalStatus: "",
    },
    onSubmit: onSubmit1,
    validationSchema: yup.object({
      firstName: yup.string().required("Champ requis"),
      lastName: yup.string().required("Champ requis"),
      email: yup.string().email("Adresse E-mail non correct"),
      maritalStatus: yup.string().required("Champ requis"),
    }),
  });

  const formik2 = useFormik({
    initialValues: {
      department: "",
      service: "",
      grade: "",
      supervisor: "",
      grossSalary: "0",
      cnssId: "",
      matricule: "",
      createAccount: false,
      password: "",
    },
    onSubmit: onSubmit2,
    validationSchema: yup.object({
      department: yup.string().required("Champ requis"),
      grossSalary: yup
        .number()
        .typeError("Valeur numérique exigée")
        .required("Champ requis"),
    }),
  });

  const formik3 = useFormik({
    initialValues: {},
    onSubmit: onSubmit3,
  });

  async function onSubmit1() {
    setStep(1);
  }

  const onInitItem = React.useCallback(() => {
    if (!isEmpty(props.item) && props.edit) {
      const user = props.item ?? {};
      const agent = user?.personals ?? {};

      if (!isEmpty(user.photo)) {
        setImageUrl(getFileURL(user.photo));
      }

      formik1.setValues({
        ...formik1.values,
        address: user?.address ?? "",
        birthDate: user?.birthDate ?? "",
        children: agent?.children ?? "0",
        email: user?.email ?? "",
        firstName: user?.firstName ?? "",
        gender: user?.gender ?? "",
        lastName: user?.lastName ?? "",
        maritalStatus: agent?.maritalStatus ?? "",
        middleName: user?.middleName ?? "",
        mobile: user?.mobile ?? "",
        phone: user?.phone ?? "",
      });
      formik2.setValues({
        ...formik2.values,
        cnssId: agent?.cnssId ?? "",
        department: agent?.department?.uid ?? "",
        grade: agent?.positionHeld?.uid ?? "",
        grossSalary: agent?.baseSalary ?? "",
        matricule: agent?.matricule ?? "",
        service: agent?.service ?? "",
        supervisor: agent?.supervisor?.uid ?? "",
      });
    }
  }, [props.item]);

  React.useEffect(() => {
    onInitItem();
  }, [props.item]);

  async function onSubmit2() {
    const {
      address,
      birthDate,
      children,
      email,
      firstName,
      gender,
      lastName,
      maritalStatus,
      middleName,
      mobile,
      phone,
      photo,
    } = formik1.values;
    const {
      cnssId,
      department,
      grade,
      grossSalary,
      matricule,
      service,
      supervisor,
      createAccount,
      password,
    } = formik2.values;
    const pers: any = {
      cnssId,
      maritalStatus,
      children: toNumber(children) ?? 0,
      department: `/api/departments/${department}`,
      baseSalary: toNumber(grossSalary) ?? 0,
      matricule,
      service,
      positionHeld: !isEmpty(grade) ? `/api/grades/${grade}` : undefined,
      status: "active",
    };
    for (const key in pers) {
      if (isEmpty(pers[key]) && typeof pers[key] !== "number") {
        delete pers[key];
      }
    }
    const data: any = {
      firstName,
      lastName,
      middleName,
      address,
      email,
      phone,
      gender,
      birthDate,
      personals: pers,
    };
    if (!isEmpty(supervisor)) {
      data.personals.supervisor = `/api/personals/${supervisor}`;
    }
    for (const key in data) {
      if (isEmpty(data[key])) {
        delete data[key];
      }
    }
    if (createAccount && !isEmpty(password)) {
      data.createAccount = true;
      data.auth = {
        plainPassword: password,
        roles: [],
      };
    }
    setLoading(true);
    if (props.edit) {
      const uid = props.item.uid as string;
      await onUpdatePhoto(uid);
      await onUpdate(uid, data);
    } else {
      await onSave(data);
    }
    setLoading(false);
  }

  async function onUpdatePhoto(id: string) {
    const photo = formik1.values.photo;
    if (!isEmpty(photo)) {
      const data = new FormData();
      data.append("file", photo as any);
      await userService
        .updatePhoto(id, data)
        .then((response) => {})
        .catch(() => {});
    }
  }

  async function onSave(data: any) {
    await userService
      .store(data)
      .then(async (response) => {
        const a = response.data;
        if (!isEmpty(a?.uid)) {
          await onUpdatePhoto(a?.uid);
        }
        await userHelper.getAllUsers(dispatch);
        setOpen(false);
        if (typeof props.setOpen === "function") {
          props.setOpen(false);
        }
        formik1.resetForm();
        formik2.resetForm();
        setStep(0);
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur d'enregistrement",
          description: error,
        });
      });
  }

  async function onUpdate(uid: string, data: any) {
    const user = props.item ?? {};
    const agent = user?.personals ?? {};
    /*data.personals.createdAt = agent.createdAt;
    await personalService.update(agent?.uid, data.personals).then(response=>{

    }).catch(()=>{

    });
    delete data.personals;
    data.created_at = user.createdAt;*/
    await userService
      .update(uid, data)
      .then(async () => {
        await userHelper.getAllUsers(dispatch);
        setOpen(false);
        if (typeof props.setOpen === "function") {
          props.setOpen(false);
        }
        formik1.resetForm();
        formik2.resetForm();
        setStep(0);
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur de modification",
          description: error,
        });
      });
  }

  async function onSubmit3() {}

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    if (typeof props.setOpen === "function") {
      props.setOpen(false);
    }
  }

  function handleSubmit() {
    switch (step) {
      case 0:
        formik1.handleSubmit();
        break;
      case 1:
        formik2.handleSubmit();
        break;
      case 2:
        formik3.handleSubmit();
        break;

      default:
        break;
    }
  }

  function handleReturn() {
    if (step === 0) {
      handleClose();
    } else {
      setStep(step - 1);
    }
  }

  return (
    <>
      {!props.edit && (
        <Button onClick={handleOpen} type="primary">
          {"Nouvel employé"}
        </Button>
      )}
      <Drawer
        title={
          !props.edit ? "Création de l'employé" : "Modification de l'employé"
        }
        onClose={handleClose}
        placement="top"
        styles={{
          wrapper: { height: "100vh" },
        }}
        className="modal__box"
        open={props.edit ? props.open : open}
      >
        <div>
          <Steps
            responsive
            size="small"
            labelPlacement="vertical"
            progressDot
            type="default"
            current={step}
          >
            <Steps.Step title="Identité de l'employé" />
            <Steps.Step title="Fonction dans l'entreprise" />
          </Steps>
        </div>
        <div className="content--modal">
          <div style={{ textAlign: "center" }}>
            <Upload
              name="file"
              listType="picture-circle"
              className="img--avatar"
              showUploadList={false}
              accept="image/*"
              //action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              action={`${process.env.REACT_APP_API_URL}/users/${props?.item?.uid}`}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
            <Typography.Text>{`Photo de profil`}</Typography.Text>
          </div>
          {step === 0 && (
            <CreateAgentIdentity formik={formik1} edit={props.edit ?? false} />
          )}
          {step === 1 && (
            <CreateAgentGrade formik={formik2} edit={props.edit ?? false} />
          )}
        </div>
        <Divider />
        <Row justify="end" gutter={12}>
          <Col>
            <Button disabled={loading} onClick={handleReturn} type="default">
              {step === 0 ? `Annuler` : `Retourner`}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
              type="primary"
            >
              {step < 1 ? `Suivant` : `Terminer`}
            </Button>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
