import { Spin } from "antd";
import React from "react";

export function Loader2(props: { loading: boolean }) {
  return (
    <>
      {props.loading && (
        <div
          style={{
            height: "100vh",
            background: "#FFF",
            position: "fixed",
            zIndex: 9999,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
    </>
  );
}
