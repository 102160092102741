import styled from "@emotion/styled";
import React from "react";
import packageJson from "../../package.json";
import { Button, Modal, Typography } from "antd";
import { GithubFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService";
import userHelper from "../utils/helper/userHelper";

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed?: boolean;
}

const codeUrl =
  "https://github.com/azouaoui-med/react-pro-sidebar/blob/master/storybook/Playground.tsx";

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
  children,
  collapsed,
  ...rest
}) => {
  const navigate = useNavigate();

  function handleLogout() {
    Modal.confirm({
      centered: true,
      title: "Déconnexion",
      content: "Voulez-vous vous déconnecter de votre session ?",
      okButtonProps: { danger: true, type: "primary" },
      okText: "Se déconnecter",
      onOk() {
        authService.logout();
        userHelper.logout();
        navigate("/home");
      },
      cancelText: "Annuler",
    });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      {collapsed ? (
        <a className="sidebar__link" href={codeUrl} target="_blank">
          <img
            style={{ width: 30 }}
            className="sidebar__footer-img"
            src={require("../assets/images/logo-cami.png")}
          />
        </a>
      ) : (
        <div className="sidebar__footer">
          <div style={{ marginBottom: "12px" }} className="logo">
            <img
              className="sidebar__footer-img"
              src={require("../assets/images/logo-cami.png")}
            />
          </div>
          <Typography.Title
            level={2}
            style={{
              fontWeight: "600",
              fontSize: 16,
              color: "#FFF",
              margin: 0,
            }}
          >
            CAMI APP
          </Typography.Title>
          <Typography.Text
            style={{
              letterSpacing: 1,
              display: "none",
              color: "#FFF",
              opacity: 0.7,
            }}
          >
            V {packageJson.version}
          </Typography.Text>
          <div style={{ marginTop: "16px" }}>
            <Button onClick={handleLogout} type="primary" danger>
              {`Se déconnecter`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
