import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type dashbordType = {
  teacher: number;
  student: number;
  level: number;
  establishment: {
    all: number;
    school: number;
    university: number;
  };
  agent: number;
  course: number;
  lesson: number;
  video: number;
};
export interface userData {
  profile: any;
  current: any;
  list: any[];
  all: any[];
  roles: any[];
  leaves: any[];
}

const initialState: userData = {
  profile: {},
  current: {},
  list: [],
  all: [],
  roles: [],
  leaves: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setUsers: (state, action: PayloadAction<any[]>) => {
      state.list = action.payload;
    },
    setAllUsers: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<any[]>) => {
      state.roles = action.payload;
    },
    setUserLeaves: (state, action: PayloadAction<any[]>) => {
      state.leaves = action.payload;
    },
  },
});
export const {
  setUserProfile,
  setCurrentUser,
  setUsers,
  setAllUsers,
  setUserRoles,
  setUserLeaves,
} = userSlice.actions;
export default userSlice.reducer;
