import { Divider, Modal, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillRadio } from "../../components/input";
import userService from "../../services/userService";
import { useDispatch } from "react-redux";
import userHelper from "../../utils/helper/userHelper";

export function CreateUser(props: {
  user: any;
  open: boolean;
  setOpen: (v: boolean) => void;
  onRefresh?: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      isUser: true,
      isAdmin: false,
      isRh: false,
    },
    onSubmit,
  });

  async function onSubmit() {
    setLoading(true);
    const { isAdmin, isRh, isUser } = formik.values;
    const roles: any[] = [];
    if (isUser) roles.push("ROLE_USER");
    if (isRh) roles.push("ROLE_RH");
    if (isAdmin) roles.push("ROLE_ADMIN");
    const data: any = {
      user: `/api/users/${props.user?.uid}`,
      roles,
    };

    await userService
      .createUser(data)
      .then(async () => {
        await userHelper.getAllUsers(dispatch);
        notification.info({
          message: "Création de compte réussi",
          description: `${userHelper.getUsername(props.user)} va recevoir un mail avec son mot de passe de passe.`,
        });
        props.setOpen(false);
        formik.resetForm();
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        notification.error({
          message: "Erreur de création de compte",
          description: error,
        });
      });
    setLoading(false);
  }

  function handleClose() {
    props.setOpen(false);
  }
  function handleSubmit() {
    formik.handleSubmit();
  }

  return (
    <>
      <Modal
        open={props.open}
        title="Création d'un compte utilisateur"
        onCancel={handleClose}
        confirmLoading={loading}
        centered
        onOk={handleSubmit}
        okText="Enregistrer"
        cancelText="Annuler"
        closable={false}
      >
        <div>
          <div
            style={{
              textAlign: "center",
              padding: 10,
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            {userHelper.getUsername(props.user)}
          </div>
          <DillRadio
            inline
            name="isUser"
            label="Compte utilisateur ?"
            formik={formik}
            enabled={false}
            list={[
              { value: true, title: "Oui" },
              { value: false, title: "Non" },
            ]}
          />
          <DillRadio
            inline
            name="isAdmin"
            label="Compte admin ?"
            formik={formik}
            list={[
              { value: true, title: "Oui" },
              { value: false, title: "Non" },
            ]}
          />
          <DillRadio
            inline
            name="isRh"
            label="Compte pour ressources humaines ?"
            formik={formik}
            list={[
              { value: true, title: "Oui" },
              { value: false, title: "Non" },
            ]}
          />
          <Divider />
        </div>
      </Modal>
    </>
  );
}
