import { isEmpty } from "lodash";
import http from "./authConfig";
import { jwtDecode } from "jwt-decode";
import dateHelpers from "../utils/helper/dateHelpers";

const TOKEN = "rh_token";
const REFRESH_TOKEN = "rh_refresh_token";

class AuthApi {
  async refreshToken() {
    const refresh_token = this.getRefreshToken();
    let rep = false;
    if (refresh_token) {
      await http
        .post("/auth/refresh", { refresh_token })
        .then((response) => response.data)
        .then((response) => {
          if (response.token) {
            rep = true;
            localStorage.setItem(TOKEN, response.token);
            localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
          }
        })
        .catch((e) => console.warn("Error", e));
    }
    return rep;
  }

  logout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }
  setToken(value: string) {
    return localStorage.setItem(TOKEN, value);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  verifyToken() {
    let result = false;
    const token = this.getToken();
    if (!isEmpty(token)) {
      const jwt = jwtDecode(token ?? "");
      const exp = jwt.exp ?? 0;
      if (exp > 0) {
        const date = new Date(exp * 1000);
        const diff = dateHelpers.getDifference(date, new Date(), "minutes");
        result = diff > 10;
      }
    }
    return result;
  }
}

export default new AuthApi();
