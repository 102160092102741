import { Button, Card, Typography } from "antd";
import React from "react";

const { Paragraph, Text, Link } = Typography;

export function MenuCard(props: {
  icon?: any;
  title: string;
  description: string;
  onClick?: () => void;
}) {
  return (
    <Card className="card__menu">
      <div className="icon">{props.icon}</div>
      <div className="title">
        <Text strong>{props.title}</Text>
      </div>
      <div className="description">
        <Paragraph>{props.description}</Paragraph>
      </div>
      <div>
        <Button onClick={props.onClick}>{`Sélectionner`}</Button>
      </div>
    </Card>
  );
}
