import { Button, notification } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import leaveService from "../../../services/leaveService";
import userHelper from "../../../utils/helper/userHelper";
import orgHelper from "../../../utils/helper/orgHelper";

export function CalendarAccept(props: {
  current: any;
  setOpen: (v: boolean) => void;
}) {
  const { current, setOpen } = props;

  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  async function handleAccept() {
    const data = { status: "in_progress" };
    setLoading(true);
    await leaveService
      .update(current.uid, data)
      .then(async () => {
        userHelper.getLeaves(dispatch);
        await orgHelper.getAllLeaves(dispatch);
        setOpen(false);
        notification.info({
          message: "Modification de la demande",
          description: "Vous avez validé la demande",
        });
      })
      .catch((reason) => {
        const error = leaveService.getError(reason);
        notification.error({ message: error });
      });
    setLoading(false);
  }

  return (
    <>
      <Button
        onClick={handleAccept}
        loading={loading}
        disabled={loading}
        type="primary"
      >
        {`Valider la demande`}
      </Button>
    </>
  );
}
