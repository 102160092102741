import { Card, Col, DatePicker, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import type { Dayjs } from "dayjs";
import { setFilterAttendance } from "../../redux/attendanceReducer";
import { useFormik } from "formik";
import userHelper from "../../utils/helper/userHelper";
import { DillSelect } from "../../components/input";
import { isEmpty } from "lodash";
import orgHelper from "../../utils/helper/orgHelper";

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

export function OrgAttendanceFilter(props: {
  setLoading: (v: boolean) => void;
}) {
  const filter = useSelector((state: RootState) => state.attendance.filter);
  const staffs = useSelector((state: RootState) => state.user.all);
  const [agents, setAgents] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<RangeValue>(null);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      agent: "",
    },
    onSubmit() {},
  });

  const onInit = React.useCallback(() => {
    let elems: any[] = [];
    staffs.filter((p) => {
      elems.push({ value: p.personals?.uid, title: userHelper.getUsername(p) });
    });
    elems = elems.sort((a, b) => a?.title?.localeCompare(b.title));
    elems.unshift({ value: "", title: "Sélectionner un agent" });
    setAgents(elems);
    const { dateRange, agent } = filter;
    setValue(dateRange);
    formik.setFieldValue("agent", agent);
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);
  React.useEffect(() => {
    onSearch();
  }, [filter]);

  const onChangeValue = React.useCallback(() => {
    const t = { ...filter };
    const agent = formik.values.agent;
    t.dateRange = value;
    t.agent = agent;
    dispatch(setFilterAttendance(t));
  }, [value, formik.values.agent]);

  React.useEffect(() => {
    onChangeValue();
  }, [value, formik.values.agent]);

  const onSearch = React.useCallback(async () => {
    const { dateRange, agent } = filter;
    let query = ``;
    if (!isEmpty(agent)) {
      query += `agent=${agent}&`;
    }
    if (!isEmpty(dateRange)) {
      const d1 = dateRange?.[0]?.format("YYYY-MM-DD");
      const d2 = dateRange?.[1]?.format("YYYY-MM-DD");
      query += `recordedAt[after]=${d1}&recordedAt[before]=${d2}&`;
    }
    if (!isEmpty(query)) {
      props.setLoading(true);
    }
    await orgHelper.getAllAttendances(dispatch, query);
    props.setLoading(false);
  }, [filter]);

  return (
    <>
      <Card className="card__2" style={{ marginBottom: 10 }}>
        <Row align="middle" gutter={12}>
          <Col>
            <RangePicker value={value} onChange={setValue} />
          </Col>
          <Col span={6}>
            <DillSelect formik={formik} name="agent" label="" list={agents} />
          </Col>
        </Row>
      </Card>
    </>
  );
}
