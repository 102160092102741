import { Icon } from "@iconify/react";
import { Button } from "antd";
import React from "react";
import { SidebarHeader } from "./sidebarHeader";

export function NavbarMobile(props: { onMenu: () => void }) {
  return (
    <div className="navbar--mobile">
      <Button onClick={props.onMenu} type="link">
        <Icon style={{ fontSize: 30 }} icon="ri:menu-3-line" />
      </Button>
      <div style={{ marginLeft: -20 }}>
        <SidebarHeader rtl />
      </div>
    </div>
  );
}
